import { Component, OnInit, inject } from '@angular/core';

import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

import { FleetService } from '../../services/fleet.service';

import { Encounter, RaceEncounter } from '../../interfaces/encounter';
import { Fleet } from '../../interfaces/fleet';
import { Race } from '../../interfaces/race';
import { StarSystem } from '../../interfaces/star-system';

@Component({
  selector: 'app-encounter-edit',
  templateUrl: './encounter-edit.component.html',
})

export class EncounterEditComponent extends DialogHandlerComponent implements OnInit {
  title: string = 'Encounter Editor';
  encounter: Encounter;
  starSystems: StarSystem[];
  scales: string[];
  states: string[];
  reStates: string[];
  allRaces: Race[];
  fleetsByRaceId: { [key: string]: Fleet[]; } = {};
  fleetService = inject(FleetService);

  fieldSelectOptions: string[];

  constructor () {
    super();
    this.encounter = this.data.document as Encounter;
    this.starSystems = this.data['starSystems'] as StarSystem[];
    this.scales = this.data['scales'] as string[];
    this.states = this.data['states'] as string[];
    this.reStates = this.data['reStates'] as string[];
    this.allRaces = this.data['races'] as Race[];
  };

  private loadFleetsForRaceId (raceId: string) {
    this.fleetService.getFleetsForRaceId$(raceId).subscribe(
      fleets => this.fleetsByRaceId[raceId] = fleets
    );

  };

  ngOnInit (): void {
    this.encounter?.raceEncounters?.forEach(re => {
      this.checkForFleets(re.raceId);
    });
  };

  checkForFleets (raceId: string) {
    if (!this.fleetsByRaceId[raceId]) {
      this.loadFleetsForRaceId(raceId);
    }
  }
  newRaceEncounter (): RaceEncounter {
    return {
      raceId: "",
      encounterTurn: 0,
      miniPulse: 0,
      active: 0,
      initiative: 1,
      initiativeBonus: 0,
      status: "New"
    };
  };

  addRaceEncounter () {
    if (!this.encounter.raceEncounters) {
      this.encounter.raceEncounters = [];
    }
    this.encounter.raceEncounters.push(this.newRaceEncounter());
  };

  deleteRaceEncounter (raceEncounter: RaceEncounter) {
    this.encounter.raceEncounters = this.encounter.raceEncounters.filter(
      re => (re.raceId !== raceEncounter.raceId)
    );
  };

  override save () {
    this.dialogRef.close({
      documentName: "Encounter",
      document: this.encounter
    });
  };
}
