<div class="mx-6 py-1 relative text-center text-white flex flex-grow flex-col flex-wrap scrollbar-none">
  <div id="_Encounter-Mgnr_TOP" class="ism-gold p-2 w-full iBTTiny:text-center flex flex-col lanPixel2xl:flex-row tracking-wider lanPixel2xl:text-left">
    {{ campaign()?.name }} - Encounters
    <a id="_create-new-encounter" data-text="Create New Encounter" class="px-2" type="button" (click)="createEncounter()">
      <ism-svg [name]="'plus'"></ism-svg>
    </a>
  </div>
  <!-- -->
  <cdk-accordion class="text-yellow-300 border border-yellow-300 flex flex-col box-border scrollbar-none">
    <!--  -->
    @for (encounter of encounters() | orderBy: "name"; track encounter) {
      <cdk-accordion-item id="_encounters_" class="text-yellow-300 border border-yellow-300 flex flex-col data-even-light" #accordionItem="cdkAccordionItem" role="button" tabindex="0" [attr.id]="'accordion-header-' + $index" [attr.aria-controls]="'accordion-body-' + $index" [attr.aria-expanded]="accordionItem.expanded" #thisAccordionItem (opened)="opened(thisAccordionItem)">
        <div id="_encounter-summary_with-icons" class="border-b border-white border-solid flex flex-row items-center" (click)="accordionItem.toggle()">
          <div class="w-full flex items-center justify-between iBTTiny:flex-col lanPixel2xl:flex-row">
            <div id="_encounter-name_" class="flex flex-grow">
              <div class="m-1">
                {{ encounter.name }}
                @if (encounter.parentEncounterId) {
                  (Child)
                }
              </div>
              <div class="m-1">Star System: {{ hashStarSystems[encounter.starSystemId]?.number }}</div>
              <div class="m-1">{{ encounter.scale }}</div>
              <div class="m-1">{{ encounter.status }}</div>
            </div>
          </div>

          <div id="_icon-set_" class="m-1 flex justify-end">
            <ism-svg id="_Edit_" class="mx-1 px-1 tooltip-tr" data-tooltip="Edit" [name]="'pencilSquare'" (click)="editEncounter($event, encounter)"></ism-svg>
            <ism-svg id="_Renew_" class="mx-1 px-1 tooltip-tr" [name]="'chevronLeftDouble'" data-tooltip="Renew" (click)="renewEncounter($event, encounter)"></ism-svg>
            <ism-svg id="_Resolution_" class="mx-1 px-1 tooltip-tr" [name]="'arrowsCircular'" data-tooltip="Test" (click)="testEncounter($event, encounter)"></ism-svg>
            <a class="mx-1 px-1 tooltip-tl" data-tooltip="Resolve" routerLink="/campaign/{{ encounter.campaignId }}/encounterResolution/{{ encounter._id }}">
              <ism-svg [name]="'checkCircle'"></ism-svg>
            </a>
            <ism-svg id="_Delete_" class="mx-1 px-1 tooltip-tl" [name]="'trashcan'" data-tooltip="Delete" (click)="deleteEncounter($event, encounter)"></ism-svg>
          </div>
        </div>

        <!-- Encounter Details -->
        <div id="_encounter-details_" class="px-4 py-2 bg-black bg-opacity-60" role="region" [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + $index" [attr.aria-labelledby]="'accordion-header-' + $index">
          <div id="_encounter-location_" class="flex">
            @if (encounter.scale === "Interception" || encounter.scale === "Tactical") {
              <div class="m-1">System Hex: {{ encounter.systemHex }}</div>
              @if (encounter.scale === "Tactical") {
                <div class="m-1">Interception Hex: {{ encounter.interceptionHex }}</div>
              }
            }
            @if (encounter.encounterTurn > 0) {
              <div class="m-1">Turns completed: {{ encounter.encounterTurn }}</div>
            }
          </div>
          <div id="_data-tables_" class="flex">
            <table id="_left-side-table_" class="w-fit">
              <tr>
                <th></th>
                <th>Turn</th>
                <th>Pulse</th>
              </tr>
              <tr>
                <td class="whitespace-nowrap">Strategic</td>
                <td class="text-center">{{ encounter.turnStrategic }}</td>
                <td class="text-center">{{ encounter.pulseStrategic }}</td>
              </tr>
              <tr>
                <td class="whitespace-nowrap">System</td>
                <td class="text-center">{{ encounter.turnSystem }}</td>
                <td class="text-center">{{ encounter.pulseSystem }}</td>
              </tr>
              @if (encounter.scale === "Interception" || encounter.scale === "Tactical") {
                <tr>
                  <td class="whitespace-nowrap">Interception</td>
                  <td class="text-center">{{ encounter.turnInterception }}</td>
                  <td class="text-center">{{ encounter.pulseInterception }}</td>
                </tr>
                @if (encounter.scale === "Tactical") {
                  <tr>
                    <td class="whitespace-nowrap">Tactical</td>
                    <td class="text-center">{{ encounter.turnTactical }}</td>
                    <td class="text-center">{{ encounter.miniPulse }}</td>
                  </tr>
                }
              }
            </table>
            <div id="_right-side_race-table_" class="ml-2 w-full flex flex-col box-border">
              <div>Races</div>
              <table class="w-full">
                <tr>
                  <th></th>
                  <th>Race</th>
                  <th>Level</th>
                  <th>Status</th>
                  <th>Turn</th>
                  <th>Initiative</th>
                  <th>Pulse</th>
                  <th class="tooltip-tr" data-text="Fleet/Counter Counts">F/C</th>
                </tr>
                @for (raceEncounter of encounter.raceEncounters; track raceEncounter; let reIndex = $index) {
                  <tr>
                    <td id="_ibm-btn_" class="min-w-[88px]"><a class="whitespace-nowrap" type="button" class="btn" href="/ibm.html?reid={{ raceEncounter.id }}" target="_blank">iBM</a></td>
                    <td id="_race-name_">{{ hashRaces[raceEncounter.raceId]?.name }}</td>
                    <td id="_level_" class="text-center whitespace-nowrap">{{ hashRaces[raceEncounter.raceId]?.techLevel }}</td>
                    <td id="_status_">{{ raceEncounter.status }}</td>
                    <td id="_turn_" class="text-center whitespace-nowrap">{{ raceEncounter.encounterTurn }}</td>
                    <td id="_initiative_" class="text-center whitespace-nowrap">{{ raceEncounter.initiative }}</td>
                    <td id="_pulse_" class="text-center whitespace-nowrap">{{ raceEncounter.miniPulse }}</td>
                    <td id="_f/c_" class="tooltip-tl whitespace-nowrap" data-text="Fleet/Counter Counts">{{ raceEncounter.fleetIds?.length || 0 }}/{{ raceEncounter.counters?.length || 0 }}</td>
                  </tr>
                }
              </table>
            </div>
          </div>
        </div>
      </cdk-accordion-item>
    }
  </cdk-accordion>
</div>
