import { Component, ElementRef, Injector, OnInit, QueryList, Signal, ViewChildren, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CdkAccordionItem } from '@angular/cdk/accordion';

import { CampaignWithRace } from '../../base/campaign-race';

import { EncounterService } from '../../services/encounter.service';
import { StarSystemService } from '../../services/star-system.service';

import { Encounter, RaceEncounter } from '../../interfaces/encounter';
import { Race } from '../../interfaces/race';
import { StarSystem } from '../../interfaces/star-system';

@Component({
  selector: 'race-encounter',
  templateUrl: './race-encounter.component.html',
})
export class RaceEncounterComponent extends CampaignWithRace implements OnInit {
  @ViewChildren(CdkAccordionItem) accordions: QueryList<CdkAccordionItem>;

  injector = inject(Injector);
  private encounterService = inject(EncounterService);
  private starSystemService = inject(StarSystemService);

  encounters: Signal<Encounter[]> = signal([]);
  raceEncountersByEncounterId: { [key: string]: RaceEncounter; } = {};
  hashStarSystems: { [key: string]: StarSystem; } = {};
  hashRaces: { [key: string]: Race; } = {};

  constructor () {
    super();
  };

  ngOnInit (): void {
    this.session.iAmBusy();
    this.encounters = toSignal<Encounter[]>(
      this.encounterService.getEncountersForRaceId$(this.race()._id),
      { injector: this.injector }
    );

    let starSystems = toSignal<StarSystem[]>(
      this.starSystemService.getAllStarSystemsForCampaign(this.campaign()._id),
      { injector: this.injector }
    );

    effect(
      () => {
        if (!starSystems() || !this.encounters()) {
          return;
        }

        this.raceEncountersByEncounterId = this.encounters().reduce(
          (hash, encounter) => {
            let raceEncounter = encounter.raceEncounters.find(
              raceEncounter => raceEncounter.raceId === this.race()._id
            );
            hash[encounter._id] = raceEncounter;
            return hash;
          }, this.raceEncountersByEncounterId
        );

        this.hashStarSystems = starSystems().reduce(
          (hash, starSystem) => (hash[starSystem._id] = starSystem, hash),
          {}
        );

        setTimeout(
          () => this.session.iAmNotBusy()
        );
      },
      { injector: this.injector }
    );
  };

  scrollIntoViewWithOffset (elementRef: ElementRef, offset: number = 0) {
    let position = 0;
    if (elementRef) {
      position = elementRef.nativeElement.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset;
    }
    window.scrollTo({
      behavior: 'smooth',
      top: position,
    });
  };

  opened (element: HTMLElement) {
    setTimeout(() => {
      let openedAccordionRef: ElementRef = new ElementRef(element);
      this.scrollIntoViewWithOffset(openedAccordionRef, 64);
    });
  };
}
