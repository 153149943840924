export interface Campaign {
  _id?: string,
  name: string,
  skyMarshals?: string[],
  turn: number,
  growthTurn: number,
  systemSize: number,
  playerIncome?: number,
  playerStartupMultiplier?: number,
  freeSyPerMc?: number,
  nprIncome?: number,
  nprStartupMultiplier?: number,
  nprStartupBonusPerTurns?: number,
  nprStartupMultiplierMax?: number
};

export const baseCampaign: Campaign = {
  name: 'New Campaign',
  turn: 1,
  growthTurn: 10,
  systemSize: 5000,
  playerIncome: 50000,
  freeSyPerMc: 300,
  nprIncome: 30000
};
