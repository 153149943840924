import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BattleManagerComponent } from './battle-manager/battle-manager.component';
import { GeneralComponent } from './general/general.component';
import { StartUpEconomicsComponent } from './start-up-economics/start-up-economics.component';
import { TurnEconomicsComponent } from './turn-economics/turn-economics.component';
import { TurnFleetOrdersComponent } from './turn-fleet-orders/turn-fleet-orders.component';
import { WarpMapComponent } from './warp-map/warp-map.component';

const routes: Routes = [
  { path: 'tutorGeneral', component: GeneralComponent },
  { path: 'tutorStartup', component: StartUpEconomicsComponent },
  { path: 'tutorTurnEconomics', component: TurnEconomicsComponent },
  { path: 'tutorTurnFleetOrders', component: TurnFleetOrdersComponent },
  { path: 'tutorBattleManager', component: BattleManagerComponent },
  { path: 'tutorWarpMap', component: WarpMapComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TutorialsRoutingModule { }
