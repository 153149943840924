import { Component, effect, inject, signal, Signal, WritableSignal } from '@angular/core';

import { RaceService } from '../../services/race.service';
import { SessionService } from '../../services/session.service';
import { TaskService } from '../../services/task.service';

import { Campaign } from '../../interfaces/campaign';
import { toSignal } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { Task, TaskState } from '../../interfaces/task';
import { Race } from '../../interfaces/race';
import { CampaignService } from '../../services/campaign.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'campaign-manager',
  templateUrl: './campaign-manager.component.html',
  host: {
    'class': 'ml-4'
  }
})
export class CampaignManagerComponent {
  campaignService = inject(CampaignService);
  raceService = inject(RaceService);
  route = inject(ActivatedRoute);
  session = inject(SessionService);
  taskService = inject(TaskService);

  myPageName: string = "campaign-manager";
  oneAtATime: boolean = true;
  raceEconomicStates: ['startUpReady', 'startUpPending', 'economicsReady', 'economicsPending'];
  raceState: { [key: string]: Race[]; } = {};
  loopCount: number = 0;
  overrideState: boolean = false;
  stopProcessLoop: boolean = true;

  campaign: Campaign;
  workingTurn: number = undefined;
  races: Signal<Race[]>;
  task: WritableSignal<Task> = signal(undefined);

  addRaceToRaceState (race, state) {
    let raceArray = this.raceState[state] || [];
    raceArray.push(race);
    this.raceState[state] = raceArray;
  };

  constructor () {
    this.session.iAmBusy();

    let campaignId = signal(this.route.snapshot.paramMap.get('campaignId') as string);
    let sessionCampaign = this.session.getCampaign();

    if (campaignId() === sessionCampaign?._id) {
      this.campaign = sessionCampaign;
      this.workingTurn = this.campaign.turn;
    }
    else {
      this.campaignService.getCampaignWithId(campaignId()).subscribe(
        campaign => {
          this.workingTurn = campaign.turn;
          this.campaign = campaign;
          this.session.setCampaign(this.campaign);
        }
      );
    }

    this.races = toSignal(
      this.raceService.getRacesForCampaignId(campaignId()).pipe(
        tap((races) => {
          for (const race of races) {
            if (race.activated && race.turnActivated <= this.workingTurn) {
              let refString;
              if (race.turnActivated === this.workingTurn) {
                if ((race.readyForEconomicsTurn || 0) === this.workingTurn) {
                  refString = 'startUpReady';
                }
                else {
                  refString = 'startUpPending';
                }
                this.addRaceToRaceState(race, refString);
              }
              if ((race.economicsCompleteTurn || 0) <= this.workingTurn) {
                if ((race.readyForEconomicsTurn || 0) === (this.workingTurn + 1)) {
                  refString = 'economicsReady';
                }
                else {
                  refString = 'economicsPending';
                }
                this.addRaceToRaceState(race, refString);
              }
            }
          }
        })
      ),
      { initialValue: undefined }
    );

    this.taskService.experimentStatus(campaignId()).subscribe(
      task => this.processTask(task)
    );

    effect(() => {
      if (!this.races() || !this.task()) {
        return;
      }
      this.session.iAmNotBusy();
    });
  };

  experimentStatusLoop () {
    this.session.iAmBusy();
    this.loopCount++;
    this.session.iAmBusy();
    this.taskService.experimentStatus(this.campaign._id).subscribe(
      task => this.processTask(task));
  };

  processTask (task: Task) {
    this.task.set(task);
    if (task && (task.state === TaskState.inProcess || this.overrideState) && !this.stopProcessLoop) {
      this.overrideState = false;
      setTimeout(() => {
        this.experimentStatusLoop();
      }, 2000);
    }
    else {
      setTimeout(() => {
        this.stopProcessLoop = true;
      });
    }
    this.session.iAmNotBusy();
  };

  experiment (campaignId: string) {
    this.session.iAmBusy();
    this.loopCount = 0;
    this.stopProcessLoop = false;
    this.overrideState = true;
    this.taskService.experiment(campaignId).subscribe(
      task => this.processTask(task)
    );
  };

  resetTaskById (taskId: string) {
    this.session.iAmBusy();
    this.taskService.resetTaskById(taskId).subscribe(
      task => this.processTask(task)
    );
  };

  stopStatusLoop () {
    this.stopProcessLoop = true;
  };
};
