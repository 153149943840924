import { Component } from '@angular/core';

@Component({
  selector: 'user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  host: {
    'class': 'flex flex-grow'
  }
})
export class UserForgotPasswordComponent {
  public first: string = '';
  public last: string = '';
  public email: string = '';
  public password: string = '';
  public role: string = '';

  onSubmit() { };

}
