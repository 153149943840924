import { Component, computed, inject, signal } from '@angular/core';

import { AuthService } from '../../auth/auth.service';
import { UserService } from "../../services/user.service";

import { Role, User } from '../../interfaces/user';

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  host: {
    'class': 'w-full'
  }
})
export class UserListComponent {
  authService = inject(AuthService);
  userService = inject(UserService);

  isAdmin = this.authService.getUser()?.role === Role.Master;

  static static_users = signal<User[]>([]);
  users = computed(() => UserListComponent.static_users());

  constructor () {
    this.userService.getAllUsers().subscribe(
      (users: User[]) => {
        UserListComponent.static_users.set(users);
      }
    );
  };

  resetPassword (user: User) {
    throw new Error('Method not implemented.');
  };

  updateUser (user: User) {
    UserListComponent.static_users.update(
      users => users.map((u: User) => {
        if (u._id === user._id) {
          u = user;
        }
        return u;
      })
    );
  };

  editUser (user: User) {
    this.userService.editUser(user, this.isAdmin, this.updateUser);
  };
}
