import { Component, ElementRef, Injector, QueryList, Renderer2, Signal, ViewChild, ViewChildren, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { authorizedMarshal } from '../../auth/auth.guard';

import { CampaignService } from '../../services/campaign.service';
import { SessionService } from '../../services/session.service';
import { UserService } from '../../services/user.service';

import { Campaign, baseCampaign } from '../../interfaces/campaign';
import { User } from '../../interfaces/user';

@Component({
  selector: 'campaign-edit',
  templateUrl: './campaign-edit.component.html',
})
export class CampaignEditComponent {
  private injector = inject(Injector);
  authorizedMarshal: boolean;
  campaignId: string = "";
  pageName: string = "campaign-edit";
  campaign: Campaign;
  activeTab: number = 1;
  campaignToEdit: Campaign;
  users: Signal<User[]> = signal([]);
  field_name: string = "myFieldName";

  items = ['Campaign Settings', 'Player Race (PR)', 'Non-Player Race (NPR)'];
  expandedIndex = 0;

  @ViewChild('accordionItem0') raceAccordion!: CdkAccordionItem;
  @ViewChild('accordionItem1') startupAccordion!: CdkAccordionItem;
  @ViewChild('accordionItem2') economicAccordion!: CdkAccordionItem;
  @ViewChildren(CdkAccordionItem, { read: ElementRef }) accordionElements!: QueryList<ElementRef>;
  linkToFollow?: ElementRef;
  selectedIndex: number = 0;

  constructor (
    public session: SessionService,
    private menu: ElementRef,
    private renderer: Renderer2,

    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private router: Router,
    private userService: UserService,
  ) {
    this.authorizedMarshal = authorizedMarshal();
  };

  ngOnInit (): void {
    this.route.params.subscribe((params: Params) => {
      this.campaignId = params['campaignId'];
      if (this.campaignId === '0') {
        this.campaign = baseCampaign;
        this.session.setCampaign(null);
      }
      else {
        this.campaign = this.session.getCampaign();
      }
      this.campaignToEdit = Object.assign({}, this.campaign);
    });
    this.users = toSignal(this.userService.getSkyMarshals(), { injector: this.injector });
  };

  saveCampaign (campaign: Campaign) {
    this.session.iAmBusy();
    this.campaignService.saveCampaign(campaign).subscribe(() => {
      this.session.setNotifyMessage('Campaign Saved');
      this.session.iAmNotBusy();
      return this.router.navigate(['/user/assignments']);
    });
  };

  opened (index: number) {
    setTimeout(() => {
      this.selectedIndex = index;
      this.field_name = this.campaignToEdit.name;
      let openedAccordion = this.accordionElements.get(index) as ElementRef;
      let newScrollTop = openedAccordion.nativeElement.offsetTop;
      this.renderer.setProperty(this.menu.nativeElement, "scrollTop", newScrollTop);

      let firstLink = openedAccordion.nativeElement.querySelector('div.menuLeftItem');
      if (firstLink) {
        this.linkToFollow = new ElementRef(firstLink);
      }
    });
  };

};
