<div class="mx-auto relative bg-white flex flex-col h-fit w-7/8 md:w-5/12 lg:w-3/8 xl:w-1/3 top-[3rem] bg-opacity-80 rounded-xl">
  <div id="_close-btn_" class="p-4 z-30 relative float-right top-0 right-0 flex items-center justify-end bg-opacity-80 rounded-xl" routerLink="/login">
    <ism-svg [name]="'xRound'" [classString]="'w-10 h-10 text-accent-blue-dk-500'"></ism-svg>
  </div>
  <!--  -->
  <div class="px-6 py-4 text-center relative -top-[4.25rem] flex flex-col sm:px-12">
    <div id="header" class="mb-2 text-center flex flex-col justify-center">
      <ism-marquee></ism-marquee>
      <div class="text-center tracking-wide ism-black">Security Department</div>
      <div class="text-center tracking-wide font-bold ism-blue">New Player Registration</div>
      <div class="text-center uppercase tracking-wider text-gray-900 text-1">Initiating Security Clearance</div>
    </div>
    <banner-alert></banner-alert>
    <banner-error></banner-error>
    <banner-notify></banner-notify>

    <div class="mx-2 mt-2 pt-2 text-left">
      <div id="_username-first-last_">
        <label for="firstName" class="mx-2 text-left uppercase leading-6 block text-1 ism-blue">Identification Required</label>
        <div class="my-1 py-1 w-full flex">
          <div class="w-full border border-gray-800 flex">
            <div class="p-2 w-1/5 border-r border-gray-800 bg-gray-100 flex rounded-tl-lg rounded-bl-lg items-center justify-center h-14 bg-opacity-40">
              <ism-svg [name]="'user'" [classString]="'w-10 h-10 text-gray-800'"></ism-svg>
            </div>
            <div class="flex items-center">
              <input id="firstName" #firstInput="ngModel" type="text" size="25" required autocomplete="first" autofocus placeholder="First Name" [(ngModel)]="first" class="px-2 h-14 w-full border-0 bg-white bg-opacity-10 p-4 text-1.25 text-black placeholder-gray-800" />
              <input id="lastName" #lastInput="ngModel" type="text" size="25" required autocomplete="last" autofocus placeholder="Last Name" [(ngModel)]="last" class="px-2 h-14 w-full rounded-br-lg rounded-tr-lg border-0 bg-white bg-opacity-10 p-4 text-1.25 text-black placeholder-gray-800" />
            </div>
          </div>
        </div>
      </div>

      <div id="_email-address_">
        <label for="email" class="mx-2 mt-2 pt-2 text-left uppercase leading-6 block text-1 ism-blue">Email Address</label>
        <div class="my-1 py-1 w-full flex">
          <div class="w-full border border-gray-800 flex">
            <div class="p-2 w-1/5 border-r border-gray-800 bg-gray-100 flex rounded-tl-lg rounded-bl-lg items-center justify-center h-14 bg-opacity-40">
              <ism-svg [name]="'envelope'" [classString]="'w-10 h-10 text-gray-800'"></ism-svg>
            </div>
            <input id="email" #emailInput="ngModel" type="email" size="70" required autocomplete="email" autofocus placeholder="emailAddress@webmail.com" [(ngModel)]="email" name="email" class="h-14 w-full rounded-br-lg rounded-tr-lg border-0 bg-white bg-opacity-10 p-4 text-1.25 text-black placeholder-gray-800" />
          </div>
        </div>
      </div>

      <div id="_passwords_">
        <label for="inputPwd" class="mt-2 pt-2 text-left uppercase leading-6 block text-1 ism-blue">Password</label>
        <div class="py-1 w-full flex items-center justify-start">
          <div class="w-full border border-gray-800 flex">
            <div class="p-2 w-1/5 border-r border-gray-800 bg-gray-100 flex rounded-tl-lg rounded-bl-lg items-center justify-center h-14 bg-opacity-40">
              <ism-svg [name]="'lock'" [classString]="'w-10 h-10 text-gray-800'"></ism-svg>
            </div>
            <input id="inputPwd" #pass1="ngModel" type="password" size="70" required minlength="6" autocomplete="current-password" placeholder="Password" [(ngModel)]="password" class="h-14 w-full rounded-br-lg rounded-tr-lg border-0 bg-white bg-opacity-10 p-2 text-1.25 text-gray-800 placeholder-gray-700" />
            <reveal-password [theInputId]="'inputPwd'"></reveal-password>
          </div>
        </div>

        <label for="inputPwd2" class="mt-2 pt-2 text-left uppercase leading-6 block text-1 ism-blue">Password</label>
        <div class="py-1 w-full flex items-center justify-start">
          <div class="w-full border border-gray-800 flex">
            <div class="p-2 w-1/5 border-r border-gray-800 bg-gray-100 flex rounded-tl-lg rounded-bl-lg items-center justify-center h-14 bg-opacity-40">
              <ism-svg [name]="'lock'" [classString]="'w-10 h-10 text-gray-800'"></ism-svg>
            </div>
            <input id="inputPwd2" #pass2="ngModel" type="password" size="70" required minlength="6" autocomplete="current-password" placeholder="Repeat" [(ngModel)]="passwordRepeat" class="h-14 w-full rounded-br-lg rounded-tr-lg border-0 bg-white bg-opacity-10 p-2 text-1.25 text-gray-800 placeholder-gray-700" />
            <reveal-password [theInputId]="'inputPwd2'"></reveal-password>
          </div>
        </div>
      </div>

      <div id="_submit-btn_" class="my-2 py-2">
        @let match = passwordRepeat === password;
        @let valid = firstInput.valid && lastInput.valid && emailInput.valid && pass1.valid && match;
        <button type="submit" class="btn text-1.25 flex w-full justify-center rounded-md px-3 py-3 leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2" [disabled]="!valid" (click)="onSubmit()">Submit</button>
      </div>
    </div>
  </div>
</div>
