<edit-dialog (action)="signalAction($event)" [title]="'Star System Creation with Overrides'" [okButtonLabel]="'Create'" class="flex flex-col">
  <ng-container edit-body>
    <div id="_CATEGORY_" class="my-4 flex items-center">
      <label class="w-1/3 text-right font-bold ism-black">Category:</label>
      <select class="mx-2 pl-2 pr-8 form-select ism-black" [(ngModel)]="selectedKey" required (change)="categoryChange(selectedKey)">
        @for (key of keys; track $index) {
          <option value="{{ key }}">{{ categories[key]?.heading }}</option>
        }
      </select>
    </div>

    <div class="my-2 mx-auto w-4/5 flex flex-col justify-center">
      @if (selectedTable()) {
        <table class="text-black border-gray-800 border-solid border-1">
          <tr>
            <th class="py-0 text-right border-gray-800 ism-black border-1">Low</th>
            <th class="py-0 text-right border-gray-800 ism-black border-1">High</th>
            <th class="py-0 text-left border-gray-800 ism-black border-1">Information</th>
          </tr>
          @for (row of selectedTable(); track $index) {
            <tr>
              <td class="py-0 text-right border-gray-800 border-solid ism-black border-1">{{ row.low }}</td>
              <td class="py-0 text-right border-gray-800 border-solid ism-black border-1">{{ row.high }}</td>
              <td class="py-0 text-left border-gray-800 border-solid ism-black border-1">{{ row.info }}</td>
            </tr>
          }
        </table>
      }

      <div class="flex items-center justify-center border border-solid border-black">
        <div id="_LOW_" class="my-1 flex items-center">
          <label class="w-1/3 text-right font-bold ism-black">Low:</label>
          <input #lowInput class="m-2 p-2 text-right form-input ism-black" type="number" [attr.max]="high" [(ngModel)]="low" required />
        </div>

        <div id="_HIGH_" class="my-1 flex items-center">
          <label class="w-1/3 text-right font-bold ism-black">High:</label>
          <input #highInput class="m-2 p-2 text-right form-input ism-black" type="number" [attr.min]="low" [(ngModel)]="high" required />
        </div>

        <div id="_ACTION_" class="my-1 flex items-center">
          <button id="_ADD-svgPlus_" type="button" data-text="Add" (click)="add(selectedKey)" [disabled]="high < low" class="mx-2 flex items-center tooltip-tr-black">
            <ism-svg [classString]="'w-8 h-8 flex items-center ism-blue'" [name]="'plus'"></ism-svg>
          </button>

          <button id="_DELETE-svgXSquare_" data-text="Delete" type="button" (click)="remove(selectedKey)" class="mx-2 flex items-center tooltip-tr-black">
            <ism-svg [classString]="'w-8 h-8 flex items-center ism-blue'" [name]="'trashcan'"></ism-svg>
          </button>
        </div>
      </div>

      <div id="_INFO-BOX_" class="my-1 w-4/5 mx-auto text-black flex flex-col bg-yellow-100 border-gray-800 border-solid border-1">
        <table>
          <tr>
            <th class="py-0 text-left border-gray-800 ism-black border-0 border-b">Category</th>
            <th class="py-0 text-right border-gray-800 ism-black border-0 border-b">Low</th>
            <th class="py-0 text-right border-gray-800 ism-black border-0 border-b">High</th>
          </tr>
          @for (key of overrideKeys(); track $index) {
            <tr>
              <td class="py-0 text-left">{{ categories[key].heading }}</td>
              <td class="py-0 text-right">{{ overrides[key].low }}</td>
              <td class="py-0 text-right">{{ overrides[key].high }}</td>
            </tr>
          }
        </table>
      </div>
    </div>
  </ng-container>
</edit-dialog>
