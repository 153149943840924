import { Component } from '@angular/core';

import { CampaignWithRace } from '../../base/campaign-race';

@Component({
  selector: 'race-base',
  templateUrl: './race-base.component.html',
  host: {
    'class': 'flex flex-grow',
  }
})
export class RaceBaseComponent extends CampaignWithRace {
  constructor () {
    super();
  }
}
