<!-- //ANCHOR - full page with modal -->
<div class="mx-auto relative bg-white flex flex-col h-fit w-7/8 md:w-5/12 lg:w-3/8 xl:w-1/3 top-[3rem] bg-opacity-80 rounded-xl">
  <!-- //ANCHOR - header -->
  <div class="px-6 py-4 text-center flex flex-col sm:px-12">
    <div class="mb-2 text-center flex flex-col justify-center">
      <ism-marquee></ism-marquee>
      <div class="text-center font-bold tracking-wider ism-black">Situation Room</div>
      <div class="text-center uppercase tracking-widest text-gray-900 text-1">Authorized Personnel Only</div>
    </div>
    <!-- //ANCHOR - banners -->
    <banner-alert></banner-alert>
    <banner-error></banner-error>
    <banner-notify></banner-notify>

    <!-- //ANCHOR - start form -->
    <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
      <div for="accountName" class="mx-2 mt-2 pt-2 text-left uppercase leading-6 block text-1 ism-blue">Identification Required</div>
      <div class="my-1 py-1 w-11/12 bg-green-200 flex md:w-full">
        <div class="w-full border border-gray-800 flex">
          <div class="p-2 w-1/5 border-r border-gray-800 bg-gray-100 flex rounded-tl-lg rounded-bl-lg items-center justify-center h-14 bg-opacity-40">
            <ism-svg [name]="'envelope'" [classString]="'w-10 h-10 text-gray-800'"></ism-svg>
          </div>
          <input name="accountName" type="email" size="70" required autocomplete="email" autofocus placeholder="emailAddress@webmail.com" [(ngModel)]="accountName" class="h-14 w-full rounded-br-lg rounded-tr-lg border-0 bg-white bg-opacity-10 p-4 text-1.25 text-black placeholder-gray-800" />
        </div>
      </div>

      <!-- //ANCHOR - INPUT Label Password -->
      <div class="mx-2 mt-2 pt-2 text-left uppercase leading-6 block text-1 ism-blue">Submit Security Credentials</div>
      <!-- //ANCHOR - INPUT Password -->
      <div class="my-1 py-1 w-11/12 flex items-center md:w-full">
        <div class="w-full border border-gray-800 flex items-center">
          <div class="p-2 w-fit border-r border-gray-800 bg-gray-100 flex items-center justify-center h-14 bg-opacity-40">
            <ism-svg [name]="'lock'" [classString]="'w-10 h-10 text-gray-800'"></ism-svg>
          </div>
          <div class="flex items-center">
            <input id="inputPwd" type="password" size="70" required autocomplete="current-password" placeholder="Password" [(ngModel)]="password" name="password" class="h-14 w-full rounded-br-lg rounded-tr-lg border-0 bg-white bg-opacity-10 p-2 text-1.25 text-gray-800 placeholder-gray-700" />
            <reveal-password [theInputId]="'inputPwd'"></reveal-password>
          </div>
        </div>
      </div>

      <!-- //ANCHOR - CHECKBOX Remember Me -->
      <div class="my-2 py-2 w-full flex items-center justify-between">
        <div class="flex items-center">
          <input id="remember" type="checkbox" [(ngModel)]="remember" name="remember" class="w-8 h-8 text-indigo-600 border-gray-700 rounded focus:ring-indigo-600" />
          <label for="remember" class="ml-3 text-gray-900 block text-1.25">Remember me</label>
        </div>
      </div>

      <div class="my-1 py-1">
        <button type="submit" class="btn text-1.25 flex w-full justify-center rounded-md px-3 py-3 leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Sign in</button>
      </div>
    </form>

    <!-- //ANCHOR - footer -->
    <div class="my-2">
      <!-- <div class="text-left text-black text-1">accountName: {{accountName}} <br></div>
      <div class="text-left text-black text-1">password: {{password}} <br></div> -->
      <div class="my-2 relative text-sm font-medium flex items-center justify-between leading-6 lanPixel2xl:flex-row">
        <div class="m-1 flex items-center justify-center">
          <a routerLink="/forgotPassword" ariaCurrentWhenActive="page" class="ism-blue whitespace-nowrap hover:text-yellow-600 visited:ism-blue font-bold leading-6 iBTTiny:text-1.125 lanPixel2xl:text-1.25">Forgot Password?</a>
        </div>
        <!--  -->
        <div class="m-1 whitespace-nowrap flex items-center justify-center text-sm text-center text-gray-700 iBTTiny:text-1.125 lanPixel2xl:text-1.25">
          <a class="font-semibold text-gray-600 hover:text-yellow-600 visited:text-gray-600" routerLink="/register" ariaCurrentWhenActive="page">Click to Register</a>
        </div>
      </div>
    </div>
  </div>
</div>
