import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authenticated, authorizedMarshal } from '../auth/auth.guard';

import { CampaignDisplayComponent } from './campaign-display/campaign-display.component';
import { CampaignEditComponent } from './campaign-edit/campaign-edit.component';
import { CampaignManagerComponent } from './campaign-manager/campaign-manager.component';
import { CampaignTablesComponent } from './campaign-tables/campaign-tables.component';
import { EncounterResolutionComponent } from './encounter-resolution/encounter-resolution.component';
import { EncounterManagerComponent } from './encounter-manager/encounter-manager.component';
import { StarSystemDisplayComponent } from './star-system-display/star-system-display.component';

const routes: Routes = [
  {
    path: 'campaign/:campaignId',
    canActivate: [authorizedMarshal],
    children: [
      {
        path: 'edit',
        canActivate: [authorizedMarshal],
        component: CampaignEditComponent
      },
      {
        path: 'tables',
        canActivate: [authorizedMarshal],
        component: CampaignTablesComponent
      },
      {
        path: 'management', // processes
        canActivate: [authorizedMarshal],
        component: CampaignManagerComponent
      },
      {
        path: 'display',
        canActivate: [authorizedMarshal],
        component: CampaignDisplayComponent
      },
      {
        path: 'encounterResolution/:eid',
        canActivate: [authorizedMarshal],
        component: EncounterResolutionComponent
      },
      {
        path: 'encounters',
        canActivate: [authorizedMarshal],
        component: EncounterManagerComponent
      },
      {
        path: 'starSystemDisplay/:starSystemId',
        canActivate: [authorizedMarshal],
        component: StarSystemDisplayComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampaignRoutingModule { }
