<div class="mr-[14.5rem] text-left relative flex flex-grow flex-wrap left-[14.5rem] scrollbar-none box-border">
  @if (race()) {
    @if (session.isMobileDevice && session.menuMobileLeftVisible) {
      <!-- //ANCHOR - Mobile Menu -->
      <left-race-menu class="mx-auto p-4 w-11/12 top-0 left-0 bottom-0 text-1.25 flex flex-col navbarLeft8 scrollbar-none absolute z-20 rounded-l-lg bg-opacity-75 overflow-y-scroll"> </left-race-menu>
    } @else if (!session.isMobileDevice) {
      <!-- //ANCHOR - Desktop Menu -->
      <left-race-menu class="px-2 w-fit min-w-[240px] text-1.25 fixed top-[3rem] left-0 bottom-0 flex flex-col navbarLeft8 scrollbar-none overflow-y-scroll"> </left-race-menu>
    }
  }
  <!-- //ANCHOR - Race Base -->
  <div id="_RACE-BASE_" class="px-4 w-full border-0 border-white flex flex-col flex-wrap overflow-scroll ism-white scrollbar-none">
    <div class="w-full flex flex-col ism-white">
      @if (campaign() != null && race() != null) {
        <div class="py-2 flex items-center iBTTiny:flex-col lanPixel2xl:flex-row iBTTiny:text-center lanPixel2xl:text-left">
          <!--  -->
          <div id="_CAMPAIGN-NAME" class="whitespace-nowrap overflow-x-clip font-bold text-1.5">
            {{ campaign()?.name }}
            <span class="mx-1 font-bold hover:no-underline">&nbsp;/&nbsp;</span>
          </div>
          <div id="_RACE-NAME" class="whitespace-nowrap font-bold text-1.5">
            {{ race()?.name }}
          </div>
        </div>
        <router-outlet></router-outlet>
      }
      <div class="h-8"></div>
    </div>
  </div>
</div>
