import { Component, effect, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';

import { CampaignService } from '../../services/campaign.service';
import { SessionService } from '../../services/session.service';
import { StarSystemService } from '../../services/star-system.service';

import { Campaign } from '../../interfaces/campaign';
import { StarSystem } from '../../interfaces/star-system';

@Component({
  selector: 'campaign-star-system-display',
  templateUrl: './star-system-display.component.html',
  host: {
    'class': 'w-full'
  }
})
export class StarSystemDisplayComponent implements OnInit {
  campaignService = inject(CampaignService);
  route = inject(ActivatedRoute);
  session = inject(SessionService);
  starSystemsService = inject(StarSystemService);

  campaignId: WritableSignal<string> = signal("");
  campaign: WritableSignal<Campaign> = signal(undefined);
  starSystemId: WritableSignal<string> = signal("");
  starSystem: WritableSignal<StarSystem> = signal(undefined);
  showMoons: boolean = false;

  constructor () {
    effect(() => {
      if (!this.campaignId() || !this.starSystemId()) {
        return;
      }

      if (this.campaignId() != this.session.getCampaign()?._id) {
        this.campaignService.getCampaignWithId(this.campaignId()).subscribe(
          campaign => {
            this.campaign.set(campaign);
            this.session.setCampaign(campaign);
          }
        );
      }
      else {
        this.campaign.set(this.session.getCampaign());
      }

      this.starSystemsService.getStarSystemAdmin(this.starSystemId()).subscribe(
        starSystem => this.starSystem.set(starSystem)
      );
    }, { allowSignalWrites: true });
  };

  ngOnInit (): void {
    this.route.paramMap.subscribe(params => {
      let campaignId: string = params.get("campaignId");
      this.campaignId.set(campaignId);

      let starSystemId: string = params.get("starSystemId");
      this.starSystemId.set(starSystemId);
    });
  };
};
