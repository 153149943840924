<div class="p-2 md:p-0 relative font-bold border-0 border-white flex flex-col iBTTiny:mx-auto iBTTiny:w-11/12 w-3/5 top-16 ism-white text-1.5 scrollbar-none overflow-x-clip">
  <div id="_CAMPAIGN-EDIT-HEADER_" class="pb-4 flex items-center iBTTiny:flex-col lanPixel2xl:flex-row">
    <div class="flex iBTTiny:flex-col lanPixel2xl:flex-row">Campaign Properties: &nbsp;</div>
    <div class="flex iBTTiny:flex-col lanPixel2xl:flex-row"> {{campaignToEdit.name}}</div>
  </div>
  <div class="m-2">
    <button class="btn btn-success btn-lg btn-block" (click)="saveCampaign(campaignToEdit)">Save</button>
  </div>

  <cdk-accordion class="ism-accordion ism-accordion-border">
    <!-- @for (item of items; track item; let index = $index) { -->

      <cdk-accordion-item id="_Campaign-Settings_" class=" ism-accordion-item" indexId="0" #accordionItem0="cdkAccordionItem" role="button" tabindex="0" [attr.aria-controls]="'accordion-body-0'" [attr.aria-description]="'Campaign'" (opened)="opened(0)">
        <div id="_HEADER_Campaign-Settings_" class="w-full flex items-center ism-accordion-border" (click)="accordionItem0.toggle()">
            <chevron-toggle [isOpen]="accordionItem0.expanded"></chevron-toggle>
            <div class="menuLeftSection" routerLinkActive="ngActiveLinkGreen">Campaign Settings</div>
        </div>

        <div id="_BODY_Campaign-Settings_" class="ml-6 text-1.25 flex flex-col w-fit" role="region" [style.display]="accordionItem0.expanded ? '' : 'none'" [attr.id]="'accordion-body-0'" [attr.aria-labelledby]="'accordion-header-0'">
          <!-- amk - testing quick-html -->
          <!-- <input-text [attr.name]="field_name" [with_label] = true [field_size] = 70 [field_label] = "'Campaign Name'"[(mutableString)]="field_name"></input-text> -->

          <div class="m-2 p-2 w-full flex items-center">
            <label class="w-1/3 text-1.25 text-white">Campaign Name: </label>
            <input class="mx-2 px-2 w-2/5 text-gray-800 form-input" size="70" type="text" [(ngModel)]="campaignToEdit.name" required>
          </div>
          @if(authorizedMarshal) {
          <div class="m-2 p-2 w-full flex items-center">
            <label class="w-1/3 text-white">SkyMarshals: </label>
            <select class="mx-2 px-2 w-2/5 text-gray-800 form-multiselect" [(ngModel)]="campaignToEdit.skyMarshals" multiple [disabled]="!authorizedMarshal">
              @for(user of users() | orderBy: 'last'; track $index) {
              <option [ngValue]="user._id">{{user.first+' '+user.last}}
              </option>
              }
            </select>
          </div>
          }
          <div class="m-2 p-2 w-full flex items-center">
            <label class="w-1/3 text-white">Turn: </label>
            <input class="mx-2 px-2 w-1/6 text-right text-gray-800 form-input" type="number" [(ngModel)]="campaignToEdit.turn" required>
          </div>
          <div class="m-2 p-2 w-full flex items-center">
            <label class="w-1/3 text-white ">Growth Turn: </label>
            <input class="mx-2 px-2 w-1/6 text-right text-gray-800 form-input" type="number" [(ngModel)]="campaignToEdit.growthTurn" required>
          </div>
          <div class="m-2 p-2 w-full flex items-center">
            <label class="w-1/3 text-white">Max System Size: </label>
            <input class="mx-2 px-2 w-1/6 text-right text-gray-800 form-input" type="number" [(ngModel)]="campaignToEdit.systemSize" required>
          </div>
              </div>
    </cdk-accordion-item>

    <cdk-accordion-item id="_Player-Race_" class=" ism-accordion-item" indexId="1" #accordionItem1="cdkAccordionItem" role="button" tabindex="1" [attr.aria-controls]="'accordion-body-1'" [attr.aria-description]="'Player Race'" (opened)="opened(1)">
      <div id="_HEADER_Player-Race_" class="w-full flex items-center ism-accordion-border" (click)="accordionItem1.toggle()">
          <chevron-toggle [isOpen]="accordionItem1.expanded"></chevron-toggle>
          <div class="menuLeftSection" routerLinkActive="ngActiveLinkGreen">Player Race (PR)</div>
      </div>

      <div id="_BODY_Player-Race_" class="ml-6 flex flex-col w-fit" role="region" [style.display]="accordionItem1.expanded ? '' : 'none'" [attr.id]="'accordion-body-1'" [attr.aria-labelledby]="'accordion-header-1'">

        <div class="m-2 p-2 w-full flex items-center">
          <label class="w-2/3 text-white">PR Start Up Income: </label>
          <input class="mx-4 px-2 w-48 text-right text-gray-800 form-input" [ngClass]="{'ism-invalid': !(campaignToEdit.playerIncome||campaignToEdit.playerStartupMultiplier)}" type="number" [(ngModel)]="campaignToEdit.playerIncome">
        </div>
        <div class="p-4 font-bold ism-gold"> --- OR ---</div>
        <div class="m-2 p-2 w-full flex items-center">
          <label class="w-2/3 text-white">PR Start Up Monthly Multiplier: </label>
          <input class="mx-4 px-2 w-48 text-right text-gray-800 form-input" [ngClass]="{'ism-invalid': !(campaignToEdit.playerIncome||campaignToEdit.playerStartupMultiplier)}" type="number" [(ngModel)]="campaignToEdit.playerStartupMultiplier">
        </div>
          </div>
  </cdk-accordion-item>

  <cdk-accordion-item id="_Non-Player-Race_" class=" ism-accordion-item" indexId="2" #accordionItem2="cdkAccordionItem" role="button" tabindex="2" [attr.aria-controls]="'accordion-body-2'" [attr.aria-description]="'Non-Player-Race'" (opened)="opened(2)">
    <div id="_HEADER_Non-Player-Race_" class="w-full flex items-center ism-accordion-border" (click)="accordionItem2.toggle()">
        <chevron-toggle [isOpen]="accordionItem2.expanded"></chevron-toggle>
        <div class="menuLeftSection" routerLinkActive="ngActiveLinkGreen">Non-Player Race (NPR)</div>
    </div>

    <div id="_BODY_Non-Player-Race_" class="ml-6 flex flex-col w-fit" role="region" [style.display]="accordionItem2.expanded ? '' : 'none'" [attr.id]="'accordion-body-2'" [attr.aria-labelledby]="'accordion-header-2'">

      <div class="m-2 p-2 w-full flex items-center">
        <label class="w-2/3 text-white">NPR - Free SY per ### MC: </label>
        <input class="mx-4 px-2 w-48 text-right text-gray-800 form-input" [ngClass]="{'ism-invalid':!(campaignToEdit.freeSyPerMc && (campaignToEdit.nprIncome||campaignToEdit.nprStartupMultiplier))}" type="number" [(ngModel)]="campaignToEdit.freeSyPerMc">
      </div>
      <div class="m-2 p-2 w-full flex items-center">
        <label class="w-2/3 text-white">NPR Start Up Income: </label>
        <input class="mx-4 px-2 w-48 text-right text-gray-800 form-input" [ngClass]="{'ism-invalid':!(campaignToEdit.freeSyPerMc && (campaignToEdit.nprIncome||campaignToEdit.nprStartupMultiplier))}" type="number" [(ngModel)]="campaignToEdit.nprIncome">
      </div>
      <div class="p-4 font-bold ism-gold"> --- OR ---</div>
      <div class="m-2 p-2 w-full flex items-center">
        <label class="w-2/3 text-white">NPR Start Up Monthly Multiplier: </label>
        <input class="mx-4 px-2 w-48 text-right text-gray-800 form-input" [ngClass]="{'ism-invalid':!(campaignToEdit.freeSyPerMc && (campaignToEdit.nprIncome||campaignToEdit.nprStartupMultiplier))}" type="number" [(ngModel)]="campaignToEdit.nprStartupMultiplier">
      </div>
      <div class="p-4 font-bold ism-gold"> --- AND (optional) ---</div>
      <div class="m-2 p-2 w-full flex items-center">
        <label class="w-2/3 text-white">NPR Start Up Bonus Monthly Multiplier per turns: </label>
        <input class="mx-4 px-2 w-48 text-right text-gray-800 form-input" type="number" [(ngModel)]="campaignToEdit.nprStartupBonusPerTurns">
      </div>
      <div class="p-4 font-bold ism-gold"> --- WITH (optional) ---</div>
      <div class="m-2 p-2 w-full flex items-center">
        <label class="w-2/3 text-white">NPR Start Up Max Multiplier: </label>
        <input class="mx-4 px-2 w-48 text-right text-gray-800 form-input" type="number" [(ngModel)]="campaignToEdit.nprStartupMultiplierMax">
      </div>
      </div>
</cdk-accordion-item>
<!-- } -->
  </cdk-accordion>
</div>
