<div class="text-1.5 ism-gold">Campaign Processes</div>
<div class="w-full flex flex-col">
  <div>Race Readiness for Turn {{ workingTurn }}</div>
  <table>
    <tr>
      <th>Race Name</th>
      <th>Economics</th>
      <th>Fleet Orders</th>
    </tr>
    @for (race of races(); track race._id) {
      <tr>
        <td>{{ race.name }}</td>
        <td class="m-auto">
          @if (race.readyForEconomicsTurn) {
            <ism-svg class="w-8 h-8 block m-auto" [name]="'checkCircle'" [classString]="'w-8 h-8 text-green-600'"></ism-svg>
          }
          <!--  -->
          @else {
            <ism-svg class="w-8 h-8 block m-auto" [name]="'xCircle'" [classString]="'w-8 h-8 text-red-600'"></ism-svg>
          }
        </td>
        <td class="text-center">
          @if (race.readyForFleetOrdersTurn) {
            <ism-svg class="w-8 h-8 block m-auto" [name]="'checkCircle'" [classString]="'w-8 h-8 text-green-600'"></ism-svg>
          }
          <!--  -->
          @else {
            <ism-svg class="w-8 h-8 block m-auto" [name]="'xCircle'" [classString]="'w-8 h-8 text-red-600'"></ism-svg>
          }
        </td>
      </tr>
    }
  </table>
  <hr />
  <div>Economic Management</div>
  <hr />
  <div>Fleet Order Management</div>
  <hr />
  <div id="_EXPERIMENT_" class="w-full flex flex-col justify-between">
    <div>Experiment</div>
    @if (task()) {
      <div class="flex">
        <div class="m-2 text-right">Turn: {{ task().turn }}</div>
        <div class="m-2 text-right">Hours: {{ task().hours }}</div>
        <div class="m-2 text-right">State: {{ task().state }}</div>
        <div class="m-2 text-right">Loop Count: {{ loopCount }}</div>
      </div>
    }
    <div class="mx-2 flex">
      <button class="mx-2 px-1 btn" type="button" (click)="experiment(campaign._id)" [disabled]="loopCount > 0">Start</button>
      <button class="mx-2 px-1 btn" type="button" (click)="resetTaskById(task()?._id)" [disabled]="!stopProcessLoop">Reset</button>
      <button class="mx-2 px-1 btn" type="button" (click)="stopStatusLoop()" [disabled]="stopProcessLoop">Stop Status Loop</button>
    </div>
  </div>
</div>
<div class="h-8"></div>
