<div class="mx-auto w-11/12 iBTTiny:text-center md:text-left text-white flex flex-col scrollbar-none">
  <div class="w-full text-left font-bold text-2" [routerLink]="['/campaign', campaign()?._id, 'display']">
    <a>{{ campaign()?.name }} - Star System Display</a>
  </div>

  <div class="flex justify-start">
    <div class="justify-items-start text-left font-bold ism-gold">Star System: {{ starSystem()?.number }} - {{ starSystem()?.type }}</div>
    <div class="mx-4 flex items-center justify-start">
      <input type="checkbox" [(ngModel)]="showMoons" />
      <span class="ml-2">Show Moons</span>
    </div>
  </div>

  <star-system-display-admin [displayMoons]="showMoons" [starSystem]="starSystem()"></star-system-display-admin>
</div>
