import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkListboxModule } from '@angular/cdk/listbox';

import { AdmiraltyModule } from './admiralty/admiralty.module';
import { CampaignModule } from "./campaign/campaign.module";
import { EconomicsModule } from './economics/economics.module';
import { ExpensesModule } from './expenses/expenses.module';
import { IncomeModule } from './income/income.module';
import { MessagesModule } from './messages/messages.module';
import { QuickHtmlModule } from "./quick-html/quick-html.module";
import { RaceModule } from './race/race.module';
import { SharedModule } from './shared/shared.module';
import { StartUpEconomicsModule } from './start-up-economics/start-up-economics.module';
import { StateDepartmentModule } from './state-department/state-department.module';
import { TestingModule } from "./testing/testing.module";
import { TutorialsModule } from './tutorials/tutorials.module';
import { UserModule } from './user/user.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CreditsComponent } from './credits/credits.component';
import { provideHttpClient } from '@angular/common/http';

@NgModule({
  imports: [
    BrowserModule,
    CampaignModule,
    CdkAccordionModule,
    CdkListboxModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    AppRoutingModule,
    AdmiraltyModule,
    EconomicsModule,
    ExpensesModule,
    IncomeModule,
    MessagesModule,
    RaceModule,
    SharedModule,
    StateDepartmentModule,
    StartUpEconomicsModule,
    TestingModule,
    TutorialsModule,
    UserModule,
    QuickHtmlModule,
  ],
  declarations: [
    AppComponent,
    CreditsComponent,
  ],
  exports: [
  ],
  providers: [
    provideHttpClient(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
