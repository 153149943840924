<ng-template #saveBtn>
  <!-- <div id="_save-btn_" class="w-full flex items-center justify-end"> -->
  <button class="my-2 w-1/5 btn btn-outline btn-primary" (click)="saveRace()">Save</button>
  <!-- </div> -->
</ng-template>

<div id="_race-display-CENTER_" class="w-fit flex flex-col iBTTiny:w-11/12 md:w-4/5 iBTTiny:mx-auto md:m-0">
  <div id="_page-header_" class="w-full flex items-center justify-between">
    <div class="whitespace-nowrap ism-gold">Race Properties</div>
    <ng-container [ngTemplateOutlet]="saveBtn"></ng-container>
  </div>
  <!-- <div>
    <span>{{ campaign() | json }}</span>
    <br />
    <span>{{ race() | json }}</span>
  </div> -->
  @if (ready()) {
    <div id="_race-properties_" class="p-2 w-full text-black bg-white flex flex-col rounded-xl">
      <gray-label-wrapper [fieldLabel]="'Race Name'" [fieldName]="'race-name'" class="w-full">
        <input name="race-name" class="w-full form-input" type="text" [(ngModel)]="race().name" required />
      </gray-label-wrapper>
      <div class="w-full flex items-center justify-stretch">
        <gray-label-wrapper [fieldLabel]="'Player Race'" [fieldName]="'player-race'" class="w-full" [labelClass]="!isMarshal ? labelsReadOnly : ''" [valueClass]="!isMarshal ? valuesReadOnly : ''">
          <input name="player-race-cb" type="checkbox" class="form-checkbox" [disabled]="!isMarshal" [checked]="race()?.playerRace" />
        </gray-label-wrapper>
        <gray-label-wrapper [fieldLabel]="'Activated'" [fieldName]="'activated'" class="w-full" [labelClass]="!isMarshal ? labelsReadOnly : ''" [valueClass]="!isMarshal ? valuesReadOnly : ''">
          <input name="activated-cb" type="checkbox" class="form-checkbox" [disabled]="!isMaster" [checked]="race()?.activated" />
        </gray-label-wrapper>
      </div>
      <div class="w-full text-black flex items-center justify-stretch">
        <gray-label-wrapper [fieldLabel]="'Star System'" [fieldName]="'star-system'" class="w-full" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
          <span>{{ starSystem()?.number }}</span>
        </gray-label-wrapper>
        <gray-label-wrapper [fieldLabel]="'Home Planet'" [fieldName]="'home-planet'" class="w-full" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
          <span>{{ homePlanet()?.locator }}</span>
        </gray-label-wrapper>
      </div>
      @if (isMarshal) {
        <div class="w-full text-black flex items-center justify-stretch">
          <gray-label-wrapper class="w-full" [fieldLabel]="'Creation Date'" [fieldName]="'creation-date'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
            <span>{{ race()?.creationDate | date: "MM/dd/yyyy" }}</span>
          </gray-label-wrapper>
        </div>
      }
      <div class="w-full text-black flex items-center justify-stretch">
        <gray-label-wrapper class="w-full" [fieldLabel]="'Creation Turn'" [fieldName]="'creation-turn'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
          <span>{{ race()?.turnCreated }}</span>
        </gray-label-wrapper>

        @if (isMaster && isFirstTurn) {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Activated Turn'" [fieldName]="'activated-turn'">
            <input name="activated-turn" type="number" min="0" max="9999" class="w-full form-iput" [(ngModel)]="race().turnActivated" />
          </gray-label-wrapper>
        }
        <!--  -->
        @else {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Activated Turn'" [fieldName]="'activated-turn'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
            <span>{{ race()?.turnActivated }}</span>
          </gray-label-wrapper>
        }
      </div>
      <div class="w-full text-black flex items-center justify-stretch">
        @if (isMarshal) {
          <gray-label-wrapper class="w-full" [fieldLabel]="'System Min'" [fieldName]="'system-min'">
            <input name="system-min" type="number" min="0" max="9999" class="w-full form-input" [(ngModel)]="race().systemMinimum" />
          </gray-label-wrapper>
        }
        <!-- -->
        @else {
          <gray-label-wrapper class="w-full" [fieldLabel]="'System Min'" [fieldName]="'system-min'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
            <span>{{ race()?.systemMinimum }}</span>
          </gray-label-wrapper>
        }
        @if (isMarshal) {
          <gray-label-wrapper class="w-full" [fieldLabel]="'System Max'" [fieldName]="'system-max'">
            <input name="system-max" type="number" min="0" max="9999" class="w-full form-input" [(ngModel)]="race().systemMaximum" />
          </gray-label-wrapper>
        }
        <!-- -->
        @else {
          <gray-label-wrapper class="w-full" [fieldLabel]="'System Max'" [fieldName]="'system-max'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
            <span>{{ race()?.systemMaximum }}</span>
          </gray-label-wrapper>
        }
      </div>
      <div class="w-full text-black flex items-center justify-stretch">
        @if (isMarshal) {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Militarism'" [fieldName]="'racial-militarism'">
            <input name="racial-militarism" type="number" min="0" max="9999" class="w-full form-input" [(ngModel)]="race().racialMilitarism" />
          </gray-label-wrapper>
        }
        <!-- -->
        @else {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Militarism'" [fieldName]="'racial-militarism'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
            <span>{{ race()?.racialMilitarism }}</span>
          </gray-label-wrapper>
        }
        @if (isMarshal) {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Success M'" [fieldName]="'success-militarism'">
            <input name="success-militarism" type="number" min="0" max="9999" class="w-full form-input" [(ngModel)]="race().successMilitarism" />
          </gray-label-wrapper>
        }
        <!-- -->
        @else {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Success M'" [fieldName]="'success-militarism'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
            <span>{{ race()?.successMilitarism }}</span>
          </gray-label-wrapper>
        }
      </div>
      <div class="w-full text-black flex items-center justify-stretch">
        @if (isMarshal) {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Chauvinism'" [fieldName]="'racial-chauvinism'">
            <input name="racial-chauvinism" type="number" min="0" max="9999" class="w-full form-input" [(ngModel)]="race().racialChauvinism" />
          </gray-label-wrapper>
        }
        <!-- -->
        @else {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Chauvinism'" [fieldName]="'racial-chauvinism'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
            <span>{{ race()?.racialChauvinism }}</span>
          </gray-label-wrapper>
        }
        @if (isMarshal) {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Success C'" [fieldName]="'success-chauvinism'">
            <input name="success-chauvinism" type="number" min="0" max="9999" class="w-full form-input" [(ngModel)]="race().successChauvinism" />
          </gray-label-wrapper>
        }
        <!-- -->
        @else {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Success C'" [fieldName]="'success-chauvinism'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
            <span>{{ race()?.successChauvinism }}</span>
          </gray-label-wrapper>
        }
      </div>
      <div class="w-full text-black flex items-center justify-stretch">
        @if (isMarshal) {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Determination'" [fieldName]="'racial-determination'">
            <input name="racial-determination" type="number" min="0" max="9999" class="w-full form-input" [(ngModel)]="race().racialDetermination" />
          </gray-label-wrapper>
        }
        <!-- -->
        @else {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Determination'" [fieldName]="'racial-determination'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
            <span>{{ race()?.racialDetermination }}</span>
          </gray-label-wrapper>
        }
        @if (isMarshal) {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Success D'" [fieldName]="'success-determination'">
            <input name="success-determination" type="number" min="0" max="9999" class="w-full form-input" [(ngModel)]="race().successDetermination" />
          </gray-label-wrapper>
        }
        <!-- -->
        @else {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Success D'" [fieldName]="'success-determination'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
            <span>{{ race()?.successDetermination }}</span>
          </gray-label-wrapper>
        }
      </div>
      @if (isMarshal) {
        <div class="w-full text-black flex items-center justify-stretch">
          <gray-label-wrapper class="w-full whitespace-normal" [fieldLabel]="'Government Selection'" [fieldName]="'government_picker'">
            <div class="w-full flex items-center justify-between">
              <div class="flex flex-col">
                <ul cdkListbox [(ngModel)]="newGovernment" aria-labelledby="LABEL-government_picker" class="flex-grow example-listbox flex-2">
                  @for (gov of limitedGovernments(); track $index) {
                    <li [cdkOption]="gov.governmentId" class="m-0 example-option">{{ gov.governmentId }} - {{ gov.name }} ({{ gov.low }} - {{ gov.high }})</li>
                  }
                </ul>
                <span class="text-1">*&nbsp;Save will be required<br /></span>
              </div>
              <div class="w-full flex flex-1 flex-col items-center ism-gold">
                <button class="my-1 w-[240px] btn btn-outline btn-primary sm:whitespace-nowrap" (click)="resetGovernmentList()">Refresh Government List</button>
                <button class="mb-1 w-[240px] btn btn-outline btn-primary sm:whitespace-nowrap" (click)="setGovernment()" [disabled]="!newGovernment">Set Government</button>
              </div>
            </div>
          </gray-label-wrapper>
        </div>
      }
      <div class="w-full text-black flex items-center justify-stretch">
        <gray-label-wrapper class="w-full" [fieldLabel]="'Government'" [fieldName]="'government'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
          <span>{{ race()?.governmentId }} - {{ government()?.name }}</span>
        </gray-label-wrapper>
        <gray-label-wrapper class="w-full" [fieldLabel]="'Tech Level'" [fieldName]="'tech-level'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
          <span>{{ race()?.techLevel }}</span>
        </gray-label-wrapper>
      </div>
      @if (isFirstTurn) {
        <div class="w-full text-black flex items-center justify-stretch">
          <gray-label-wrapper class="w-full" [fieldLabel]="'Initial PU'" [fieldName]="'pu'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
            <span>{{ race()?.pu }}</span>
          </gray-label-wrapper>
          <gray-label-wrapper class="w-full" [fieldLabel]="'Initial PTU'" [fieldName]="'initial-ptu'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
            <span>{{ race()?.ptu }}</span>
          </gray-label-wrapper>
        </div>
        <div class="w-full text-black flex items-center justify-stretch">
          @if (isMarshal) {
            <gray-label-wrapper class="w-full" [fieldLabel]="'Free PCF'" [fieldName]="'free-pcf'">
              <input name="free-pcf" type="number" min="0" max="9999" class="w-full form-input" [(ngModel)]="race().freePcf" />
            </gray-label-wrapper>
          }
          <!-- -->
          @else {
            <gray-label-wrapper class="w-full" [fieldLabel]="'Free PCF'" [fieldName]="'free-pcf'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
              <span>{{ race()?.freePcf }}</span>
            </gray-label-wrapper>
          }
          @if (isMarshal) {
            <gray-label-wrapper class="w-full" [fieldLabel]="'Free PTU'" [fieldName]="'free-ptu'">
              <span>{{ race()?.freePtu }}</span>
            </gray-label-wrapper>
          }
        </div>
        <gray-label-wrapper class="w-full" [fieldLabel]="'Max X Ships'" [fieldName]="'max-x'">
          <span>{{ race()?.maxX }}</span>
        </gray-label-wrapper>
      }
      <div class="w-full text-black flex items-center justify-stretch">
        <gray-label-wrapper class="w-full" [fieldLabel]="'Turn'" [fieldName]="'turn'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
          <span>{{ campaign()?.turn }}</span>
        </gray-label-wrapper>
        <gray-label-wrapper class="w-full" [fieldLabel]="'Completed Turn'" [fieldName]="'race-turn'" [labelClass]="'bg-gray-200 bg-opacity-60 text-black'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
          <span>{{ race()?.economicsCompleteTurn }}</span>
        </gray-label-wrapper>
      </div>

      <div class="w-full text-black flex items-center justify-stretch">
        <gray-label-wrapper class="w-full" [fieldLabel]="'Budget Ready'" [fieldName]="'ready-eco'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
          <input name="ready-eco" type="checkbox" class="form-checkbox" [disabled]="true" [checked]="race()?.readyForEconomicsTurn" />
        </gray-label-wrapper>
        <gray-label-wrapper class="w-full" [fieldLabel]="'Orders Ready'" [fieldName]="'ready-orders'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
          <input name="ready-orders" type="checkbox" class="form-checkbox" [disabled]="true" [checked]="race()?.readyForFleetOrdersTurn" />
        </gray-label-wrapper>
      </div>
      @if (users()) {
        @if (isMarshal) {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Controlled By'" [fieldName]="'players'">
            <ul cdkListbox cdkListboxMultiple [(ngModel)]="race().playerIds" aria-labelledby="LABEL-players" class="example-listbox">
              @for (user of users(); track user._id) {
                <li [cdkOption]="user._id" class="m-0 example-option">{{ user.first }} {{ user.last }}</li>
              }
            </ul>
          </gray-label-wrapper>
        }
        <!-- -->
        @else {
          <gray-label-wrapper class="w-full" [fieldLabel]="'Controlled By'" [fieldName]="'players'" [labelClass]="labelsReadOnly" [valueClass]="valuesReadOnly">
            <ul class="example-listbox">
              @for (user of users(); track user._id) {
                @if (race()?.playerIds.includes(user._id)) {
                  <li class="m-0 example-option">{{ user.first }} {{ user.last }}</li>
                }
              }
            </ul>
          </gray-label-wrapper>
        }
      }
    </div>
  }
  <div id="_page-header_" class="w-full flex items-center justify-end">
    <ng-container [ngTemplateOutlet]="saveBtn"></ng-container>
  </div>
</div>
