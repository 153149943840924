import { Component, Input } from '@angular/core';

import { AuthService } from "../../auth/auth.service";

@Component({
  selector: 'user-login',
  templateUrl: './user-login.component.html',
  host: {
    'class': 'flex flex-grow'
  }
})
export class UserLoginComponent {
  // @Input() AccountName: string = '';
  // @Input() Password: string = '';
  public accountName: string = '';
  public password: string = '';
  public remember: boolean = false;


  constructor(private authService: AuthService) { };

  onSubmit() {
    this.authService.login(this.accountName, this.password, this.remember);
  };
};
