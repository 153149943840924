<div class="mx-6 py-1 w-full text-center text-white md:w-7/8 scrollbar-none">
  <div id="_user-listing_" class="ism-white p-2 w-full sticky z-30 top-[58px] border border-b-0 border-white text-1.875 font-bold iBTTiny:text-center flex flex-col justify-center tracking-wider lanPixel2xl:text-left bg-black bg-opacity-75">User Listing</div>

  <div class="p-2 w-full border border-t-0 border-white flex flex-col items-center justify-center">
    <table class="z-40">
      <tr>
        <th class="bg-black ism-white sticky z-40 top-[58px]">First Name</th>
        <th class="bg-black ism-white sticky z-40 top-[58px]">Last Name</th>
        <th class="bg-black ism-white sticky z-40 top-[58px]">Email</th>
        <th class="bg-black ism-white sticky z-40 top-[58px]">Role</th>
        <th class="bg-black ism-white sticky z-40 top-[58px]">Action</th>
      </tr>
      @for (user of users(); track $index) {
        <tr>
          <td class="ism-white">{{ user.first }}</td>
          <td class="ism-white">{{ user.last }}</td>
          <td class="ism-white">{{ user.email }}</td>
          <td class="ism-white">{{ user.role }}</td>
          <td class="ism-white">
            <div class="flex">
              <ism-svg id="_edit-user_" [name]="'pencilSquare'" data-tooltip="Edit User" [classString]="'w-8 h-8 flex items-center text-white hover:text-yellow-300 visited:text-black'" (click)="editUser(user)"></ism-svg>
              @if (user._id != authService.getUser()._id) {
                <ism-svg (click)="resetPassword(user)" id="_reset-user-password_" [name]="'arrowsCircular'" data-tooltip="Reset Password" [classString]="'w-8 h-8 flex items-center text-white hover:text-yellow-300 visited:text-black'"></ism-svg>
              }
            </div>
          </td>
        </tr>
      }
    </table>
  </div>
</div>
