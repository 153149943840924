<style>
  .encounter-edit td {
    width: 16.7%;
  }
</style>
<edit-dialog (action)="signalAction($event)" [title]="title" class="flex flex-col">
  <ng-container edit-body>
    <div class="text-black flex flex-col">
      <table class="encounter-edit m-2 border-black border-none">
        <tr id="_row-1_">
          <td id="_nameLabel_" class="p-1 text-right border-black border-none ism-black">
            <label for="encounter_name" class="px-2 whitespace-nowrap">Name:</label>
          </td>
          <td id="_nameInput_" colspan="3" class="p-1 border border-black ism-black">
            <input id="encounter_name" type="text" class="px-2 w-full form-input" [(ngModel)]="encounter.name" />
          </td>
          <td id="_scaleLabel_" class="p-1 text-right border-black border-none ism-black">
            <label for="scale" class="px-2 whitespace-nowrap">Scale</label>
          </td>
          <td id="_scaleSelect_" class="p-1 border border-black ism-black">
            <select id="scale" class="w-full form-select whitespace-nowrap text-1.25" [(ngModel)]="encounter.scale">
              @for (scale of scales; track scale) {
                <option [ngValue]="scale">{{ scale }}</option>
              }
            </select>
          </td>
        </tr>
        <tr id="_row-2_">
          <td id="_blank-col-spacer_" colspan="2" class="border-black border-none"></td>
          <td id="_starSystemIdLabel_" class="p-1 text-right border-black border-none ism-black">
            <label for="starSystemId" class="px-2 whitespace-nowrap">Star System</label>
          </td>
          <td id="_starSystemIdSelect_" class="p-1 border border-black ism-black">
            <select id="starSystemId" class="px-2 w-full form-select whitespace-nowrap text-1.25" [(ngModel)]="encounter.starSystemId">
              @for (starSystem of starSystems | orderBy: "number"; track starSystem) {
                <option [ngValue]="starSystem._id">{{ starSystem.number }}</option>
              }
            </select>
          </td>
          <td id="_statusLabel_" class="p-1 text-right border-black border-none ism-black">
            <label for="status" class="px-2 whitespace-nowrap">Status</label>
          </td>
          <td id="_statusSelect_" class="p-1 border border-black ism-black">
            <select id="status" class="px-2 w-full form-select whitespace-nowrap text-left text-1.25" [(ngModel)]="encounter.status">
              @for (status of states; track status) {
                <option [ngValue]="status">{{ status }}</option>
              }
            </select>
          </td>
        </tr>
        <tr id="_row-3_">
          <td id="_blank-col-spacer_" colspan="2" class="border-black border-none"></td>
          <td id="_encounterTurnLabel_" class="p-1 text-right border-black border-none ism-black">
            <label for="encounterTurn" class="px-2 whitespace-nowrap">Encounter Turn</label>
          </td>
          <td id="_encounterTurnInput_" class="p-1 border border-black ism-black">
            <input id="encounterTurn" type="number" class="px-2 w-full border-white form-input" readonly [(ngModel)]="encounter.encounterTurn" />
          </td>
          <td id="_miniPulseLabel_" class="p-1 text-right border-black border-none ism-black">
            <label for="miniPulse" class="px-2 whitespace-nowrap">Mini-Pulse</label>
          </td>
          <td id="_miniPulseInput_" class="p-1 border border-black ism-black">
            <input id="miniPulse" type="number" class="px-2 w-full border-white form-input" readonly [(ngModel)]="encounter.miniPulse" />
          </td>
        </tr>
        <tr id="_row-4_">
          <td id="_blank-col-spacer_" colspan="2" class="border-black border-none"></td>
          <td id="_monthLabel_" class="p-1 text-right border-black border-none ism-black">
            <label for="month" class="px-2 whitespace-nowrap" data-tooltip="Strategic Turn">Month</label>
          </td>
          <td id="_monthInput_" class="p-1 border border-black ism-black">
            <input id="month" min="1" max="12" type="number" class="px-2 w-full border-white form-input" [(ngModel)]="encounter.turnStrategic" />
          </td>
          <td id="_dayLabel_" class="p-1 text-right border-black border-none ism-black">
            <label for="day" class="px-2 whitespace-nowrap" data-tooltip="Strategic Pulse">Day</label>
          </td>
          <td id="_dayInput_" class="p-1 border border-black ism-black">
            <input id="day" min="1" max="30" type="number" class="px-2 w-full border-white form-input" [(ngModel)]="encounter.pulseStrategic" />
          </td>
        </tr>
        <tr id="_row-5_">
          <td id="_blank-col-spacer_" colspan="2" class="border-black border-none"></td>
          <td id="_systemTurnLabel_" class="p-1 text-right border-black border-none ism-black">
            <label for="systemTurn" class="px-2 whitespace-nowrap">System Turn</label>
          </td>
          <td id="_systemTurnSystem_" class="p-1 border border-black ism-black">
            <input id="systemTurn" min="1" max="2" type="number" class="px-2 w-full border-white border-none form-input" [(ngModel)]="encounter.turnSystem" />
          </td>
          <td id="_systemPulseLabel_" class="p-1 text-right border-black border-none ism-black">
            <label for="systemPulse" class="px-2 whitespace-nowrap">System Pulse</label>
          </td>
          @if (encounter.scale === "System") {
            <td id="_miniPulseInput_" class="px-3 py-1 border border-black ism-black">
              {{ encounter.miniPulse / 18 | number: "1.0-0" }}
            </td>
          } @else {
            <td id="_miniPulseInput_" class="p-1 border border-black ism-black">
              <input id="systemPulse" type="number" class="px-2 w-full border-white border-none form-input" min="1" max="6" [(ngModel)]="encounter.pulseSystem" />
            </td>
          }
        </tr>
        @if (encounter.scale === "Interception" || encounter.scale === "Tactical") {
          <tr id="_row-6_">
            <td id="_systemHexLabel_" class="p-1 text-right border-black border-none ism-black">
              <label for="systemHex" class="px-2 whitespace-nowrap">System Hex</label>
            </td>
            <td id="_systemHexInput_" class="p-1 border border-black ism-black">
              <input id="systemHex" type="text" class="px-2 w-full border-white border-none" [(ngModel)]="encounter.systemHex" />
            </td>
            <td class="p-1 text-right border-black border-none ism-black">
              <label for="interceptionTurn" class="px-2 whitespace-nowrap">Interception Turn</label>
            </td>
            <td class="p-1 border border-black ism-black">
              <input id="interceptionTurn" min="1" max="4" type="number" class="px-2 w-full border-white border-none form-input" [(ngModel)]="encounter.turnInterception" />
            </td>
            <td class="p-1 text-right border-black border-none ism-black">
              <label for="miniPulse" class="px-2 whitespace-nowrap">Interception Pulse</label>
            </td>
            @if (encounter.scale === "Interception") {
              <td class="px-3 py-1 border-black ism-black">
                {{ encounter.miniPulse / 18 | number: "1.0-0" }}
              </td>
            } @else {
              <td class="p-1 border-black ism-black">
                <input id="interceptionPulse" min="1" max="6" type="number" class="px-2 w-full border-white border-none form-input" [(ngModel)]="encounter.pulseInterception" />
              </td>
            }
          </tr>
        }
        @if (encounter.scale === "Tactical") {
          <tr id="_row-7_" class="border-black ism-black">
            <td id="_interceptioinHexLabel_" class="p-1 text-right border-none">
              <label for="interceptionHex" class="px-2 whitespace-nowrap">Interception Hex</label>
            </td>
            <td id="_interceiptionHexInput_" class="p-1 border border-black">
              <input id="interceptionHex" type="text" class="px-2 w-full border-white border-none form-input" [(ngModel)]="encounter.interceptionHex" />
            </td>
            <td id="_tacticalTurnLabel_" class="p-1 text-right border-none">
              <label for="tacticalTurn" class="px-2 whitespace-nowrap">Tactical Turn</label>
            </td>
            <td id="_tacticalTurnInput_" class="p-1 text-right border border-black">
              <input id="tacticalTurn" type="number" class="px-2 w-full border-white border-none form-input" min="1" max="10" [(ngModel)]="encounter.turnTactical" />
            </td>
            <td id="_miniPulseLabel_" class="p-1 text-right border-none">
              <label for="miniPulse" class="px-2 whitespace-nowrap">Tactical Pulse</label>
            </td>
            <td id="_miniPulseInput_" class="px-3 py-1 border border-black">
              {{ encounter.miniPulse / 18 | number: "1.0-0" }}
            </td>
          </tr>
        }
      </table>
      <div id="_Race-Mgmt_" class="m-0 p-0 text-black border-0 flex flex-col">
        <div id="_SUBTITLE-Race-Mgmt_" class="p-1 bg-yellow-100 flex items-center justify-center">
          <div class="p-2 whitespace-nowrap">Race Management</div>
          <div id="_IconSet" class="px-2 items-center">
            <ism-svg [name]="'plus'" data-tooltip="Add Race" [classString]="'w-8 h-8 flex items-center text-black hover:text-green-700 visited:text-black'" (click)="addRaceEncounter()"></ism-svg>
          </div>
        </div>

        <div id="_RACE-LIST_" class="flex flex-col">
          <table id="_RaceTable_" class="px-4 text-black border-none">
            @for (re of encounter.raceEncounters; track re) {
              <tr id="_RaceDetails_" class="data-odd-dark">
                <td class="border-t border-b border-l-0 border-r-0 border-black">
                  <table id="_RaceDetail-Table_" class="border-none">
                    <tr id="_Row-1_">
                      <td id="_raceNameLabel_" class="px-2 text-right border-none whitespace-nowrap">Race:</td>
                      <td id="_raceNameSelect_" colspan="4" class="px-2 border border-black ism-black">
                        <select id="{{ 'raceId' + re._id }}" class="px-2 w-full form-select ism-black" [(ngModel)]="re.raceId" (change)="checkForFleets(re.raceId)">
                          @for (race of allRaces; track race._id) {
                            <option value="{{ race._id }}">{{ race.name }}</option>
                          }
                        </select>
                      </td>
                      <td id="_delete-Btn_" class="text-center border-none">
                        <ism-svg [name]="'trashcan'" data-tooltip="Delete Race" [classString]="'w-8 h-8 flex items-center text-black hover:text-red-800 visited:text-black'" (click)="deleteRaceEncounter(re)"></ism-svg>
                      </td>
                    </tr>
                    <tr id="_row-2_">
                      <td id="_ActiveLabel_" class="px-2 text-right border-none whitespace-nowrap">Active:</td>
                      <td id="_ActiveInput_" class="p-1 text-right border border-black ism-black">
                        <input id="{{ 'active' + re._id }}" class="m-1 p-1 w-full ism-black form-input" type="number" min="0" max="1" [(ngModel)]="re.active" required />
                      </td>
                      <td id="_iBonusLabel_" class="px-2 text-right border-none whitespace-nowrap">Initiative Bonus:</td>
                      <td id="_iBonusInput_" class="p-1 border border-black ism-black">
                        <input id="{{ 'initiativeBonus' + re._id }}" class="m-1 p-1 w-full ism-black form-input" type="number" min="0" max="10" [(ngModel)]="re.initiativeBonus" required />
                      </td>
                      <td id="_statusLabel_" class="px-2 text-right border-none whitespace-nowrap">Status:</td>
                      <td id="_statusSelect_" class="p-1 border border-black ism-black">
                        <select id="{{ 'status' + re._id }}" disabled class="px-2 w-full form-select ism-black" [(ngModel)]="re.status">
                          @for (raceState of reStates; track raceState) {
                            <option value="{{ raceState }}">{{ raceState }}</option>
                          }
                        </select>
                      </td>
                    </tr>
                    <tr id="_row-3_">
                      <td id="_notes-commentsLabel_" class="p-2 text-right align-top border-none whitespace-nowrap">Comments:</td>
                      <td id="_notes-commentsTextarea_" class="align-top border border-black ism-black">
                        <textarea id="{{ 'note' + re._id }}" class="px-2 text-black form-textarea" cols="28" [(ngModel)]="re.note" rows="3"></textarea>
                      </td>
                      <td id="_fleetLabel_" class="p-2 text-right align-top border-none">Fleets:</td>
                      <td id="_fleetSelect_" colspan="3" class="align-top border border-black whitespace-nowrap">
                        <select id="{{ 'fleetIds' + re._id }}" class="px-2 w-full ism-black form-multiselect]" multiple [(ngModel)]="re.fleetIds">
                          @for (fleet of fleetsByRaceId[re.raceId]; track fleet._id) {
                            <option value="{{ fleet._id }}">{{ fleet.fleetName }}</option>
                          } @empty {
                            <option value="">No Fleets</option>
                          }
                        </select>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            }
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</edit-dialog>
