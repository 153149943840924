<div class="mx-auto w-11/12 text-white flex flex-col iBTTiny:text-center md:text-left scrollbar-none">
  <div id="_Campaign Name" data-tooltip="Campaign Name" class="w-full text-left font-bold whitespace-nowrap text-2 ism-gold tooltip-tr">
    {{ campaign()?.name }}
  </div>

  @if (starSystems()?.length === 0) {
    <div class="m-2 w-full">This campaign has no star systems</div>
  }

  <div id="_Data-Tables_" class="w-full text-center text-white flex iBTTiny:flex-col md:flex-row">
    <!-- //ANCHOR - Star System List - Left-Side -->
    <div class="iBTTiny:mx-auto md:mr-1 iBTTiny:w-11/12 md:w-3/5 overscroll-y-auto">
      <table id="_Star-Systems-Data_" class="w-full border">
        <tr>
          <th colspan="5">
            <div class="flex items-center justify-between">
              <div class="flex items-center justify-start">
                <div id="_Star System" class="text-1.25 whitespace-nowrap ism-white">Star Systems</div>

                <div id="_IconSet" class="flex flex-1 items-center">
                  <ism-svg [name]="'plus'" data-tooltip="New Star System" class="px-2 tooltip-tr" (click)="newStarSystem()"></ism-svg>
                  <!-- ng-href="/starSystemDisplay" -->
                  <ism-svg [name]="'gear'" data-tooltip="New Star System with Overrides" class="tooltip-tr" (click)="overrideStarSystem()"></ism-svg>
                </div>
              </div>
              <div class="text-1.25 align-middle text-right border-0 whitespace-nowrap ism-white">Count: {{ starSystems()?.length }}</div>
            </div>
          </th>
        </tr>
        <tr>
          <th class="w-1/5 border border-gray-900 iBTTiny:text-1 md:text-1.125 ism-black bg-gray-100 bg-opacity-80">ID</th>
          <th class="border border-gray-900 iBTTiny:text-1 md:text-1.125 ism-black bg-gray-100 bg-opacity-80">Type</th>
          <th class="border border-gray-900 iBTTiny:text-1 md:text-1.125 ism-black bg-gray-100 bg-opacity-80" colspan="2">Star Types</th>
          <th class="border border-gray-900 iBTTiny:text-1 md:text-1.125 ism-black bg-gray-100 bg-opacity-80">View</th>
        </tr>
        @for (starSystem of starSystems() | orderBy: "number"; track starSystem._id) {
          <tr class="data-odd-light">
            <td id="_StarSysDisplay" class="w-fit iBTTiny:text-1 md:text-1.125 text-center whitespace-nowrap" (click)="openStarSystem(starSystem)">
              <a>{{ starSystem.number }}</a>
            </td>

            <td id="_Star-Types_" class="w-fit iBTTiny:text-1 md:text-1.125 text-center whitespace-nowrap">
              {{ starSystem.type }}
            </td>

            <!-- If Star Types is more than one, show first star -->
            @if (starSystem.stars.length === 2) {
              <td class="w-fit iBTTiny:text-1 md:text-1.125 text-center whitespace-nowrap">
                <span>{{ starSystem.stars[0].type }}</span>
              </td>
            }

            <!-- If Star Types is zero or one star, span two columns, show first star -->
            @if (starSystem.stars.length <= 1) {
              <td class="iBTTiny:text-1 md:text-1.125 text-center whitespace-nowrap" colspan="2">
                @if (starSystem.stars.length === 1) {
                  <span>{{ starSystem.stars[0].type }}</span>
                }
              </td>
            }

            <!-- If Star Types is more than one, show second star -->
            @if (starSystem.stars.length === 2) {
              <td class="iBTTiny:text-1 md:text-1.125 text-center whitespace-nowrap">
                {{ starSystem.stars[1].type }}
              </td>
            }

            <td id="_ibm-link_">
              <div class="flex items-center justify-center ism-gold whitespace-nowrap">
                <a data-tooltip="Opens in Another Tab" class="flex items-center justify-center ism-gold tooltip-tr" href="/ibm.html?sid={{ starSystem._id }}" target="_blank">
                  <ism-svg [name]="'eye'" [classString]="'w-6 h-6 ism-gold hover:ism-white'"></ism-svg>
                </a>
              </div>
            </td>
          </tr>
        }
      </table>
    </div>

    <!-- //ANCHOR - Race Right-Side -->
    <div class="iBTTiny:mx-auto md:ml-2 iBTTiny:mt-2 md:mt-0 iBTTiny:w-11/12 md:w-3/5 overscroll-y-auto">
      <table id="_Race-Data_" class="w-full border">
        <tr>
          <th colspan="5">
            <div class="flex items-center justify-between">
              <div id="_Star System" class="text-1.25 ism-white">Races</div>
              <div class="text-1.25 text-right ism-white">Count: {{ races()?.length }}</div>
            </div>
          </th>
        </tr>
        <tr class="">
          <th class="iBTTiny:text-1 md:text-1.125 border-gray-900 border ism-black bg-gray-100 bg-opacity-80 whitespace-nowrap">Race Name</th>
          <th class="w-60 iBTTiny:text-1 md:text-1.125 border-gray-900 border ism-black bg-gray-100 bg-opacity-80">TL</th>
          <th class="iBTTiny:text-1 md:text-1.125 border-gray-900 border ism-black bg-gray-100 bg-opacity-80 whitespace-nowrap">PU</th>
          <th class="iBTTiny:text-1 md:text-1.125 border-gray-900 border ism-black bg-gray-100 bg-opacity-80 whitespace-nowrap">Home System</th>
          <th class="iBTTiny:text-1 md:text-1.125 border-gray-900 border ism-black bg-gray-100 bg-opacity-80">Delete</th>
        </tr>
        @for (race of races() | orderBy: "name"; track race._id) {
          <tr class="data">
            <td class="w-fit iBTTiny:text-1 md:text-1.125 text-left whitespace-nowrap" (click)="openRace(race)">
              <a>{{ race.name }}</a>
            </td>
            <td class="w-60 iBTTiny:text-1 md:text-1.125 text-center whitespace-nowrap">
              <tech-level [techLevel]="race.techLevel"></tech-level>
            </td>
            <td class="iBTTiny:text-1 md:text-1.125 text-center whitespace-nowrap">{{ race.pu }}</td>
            <td class="iBTTiny:text-1 md:text-1.125 text-center" (click)="openStarSystem(starSystemLookup[race.starSystemId])">
              <a>{{ starSystemLookup[race.starSystemId]?.number }}</a>
            </td>
            <td>
              <div class="flex items-center justify-center ism-gold whitespace-nowrap">
                <a id="_DELETE-race_" type="button" data-tooltip="Delete Race" class="mx-1 flex items-left iBTTiny:text-1 md:text-1.125 whitespace-nowrap tooltip-tr" (click)="deleteRace(race)">
                  <ism-svg [name]="'trashcan'" [classString]="'w-6 h-6 flex items-center ism-gold'"></ism-svg>
                </a>
              </div>
            </td>
          </tr>
        }
      </table>
    </div>
  </div>

  <div class="h-6"></div>
</div>
