import { AfterViewInit, Component, ElementRef, QueryList, ViewChildren, WritableSignal, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CdkAccordionItem } from "@angular/cdk/accordion";
import { Dialog } from '@angular/cdk/dialog';
import { Router } from '@angular/router';
import { tap } from 'rxjs';

import { AreYouSure2Component } from '../../shared/are-you-sure2/are-you-sure2.component';

import { AuthService } from '../../auth/auth.service';
import { CampaignService } from '../../services/campaign.service';
import { RaceService } from '../../services/race.service';
import { SessionService } from '../../services/session.service';

import { authorizedMarshal } from '../../auth/auth.guard';

import { Campaign } from '../../interfaces/campaign';
import { Race } from '../../interfaces/race';
import { User } from '../../interfaces/user';

@Component({
  selector: 'user-assignments',
  templateUrl: './user-assignments.component.html',
  host: {
    'class': 'flex flex-grow'
  }
})
export class UserAssignmentsComponent implements AfterViewInit {
  @ViewChildren(CdkAccordionItem) accordions: QueryList<CdkAccordionItem>;

  private authService = inject(AuthService);
  private campaignService = inject(CampaignService);
  private dialog = inject(Dialog);
  private raceService = inject(RaceService);
  private router = inject(Router);
  public session = inject(SessionService);

  user: User = this.authService.getUser();
  isMarshall: boolean = authorizedMarshal();
  racesForCampaignId: Map<string, Race[]> = new Map();
  campaignIds: WritableSignal<string[]> = signal(undefined);
  campaigns: WritableSignal<Campaign[]> = signal(undefined);
  raceCampaignIds: WritableSignal<string[]> = signal([]);
  processedRaces: WritableSignal<boolean> = signal(false);
  processedCampaigns: WritableSignal<boolean> = signal(false);

  constructor () {
    if (this.user == null) {
      throw new Error('No User Assigned!');
    }

    this.campaignService.getCampaigns().subscribe(
      campaigns => this.campaigns.set(campaigns)
    );

    let controlledRaces = toSignal(this.raceService.getRacesForUserId(this.user._id));

    effect(() => {
      if (!controlledRaces()) {
        return;
      }

      for (const race of controlledRaces()) {
        let racesForCampaignId: Race[] = this.racesForCampaignId.get(race.campaignId) || [];
        racesForCampaignId.push(race);
        this.racesForCampaignId.set(race.campaignId, racesForCampaignId);
      }

      this.raceCampaignIds.set(Array.from(this.racesForCampaignId.keys()));
      this.processedRaces.set(true);
    }, { allowSignalWrites: true });

    effect(() => {
      // if campaigns are already processed, nothing here required again
      let ids = this.campaignIds();
      let campaigns = this.campaigns();
      let hasBeenProcessed = (ids !== undefined);
      let campaignsHaveBeenFetched = (campaigns !== undefined);
      if (hasBeenProcessed || !campaignsHaveBeenFetched) {
        return;
      }
      if (ids === undefined) {
        ids = [];
      }

      for (const campaign of campaigns) {
        if (ids.indexOf(campaign._id) === -1) {
          ids.push(campaign._id);
        }
      }
      this.campaignIds.set(ids);
      this.processedCampaigns.set(true);
    }, { allowSignalWrites: true });

    effect(() => {
      if (!(this.processedRaces() && this.processedCampaigns())) {
        return;
      }

      let ids = this.campaignIds() || [];
      for (const campaignId of this.raceCampaignIds()) {
        if (ids.indexOf(campaignId) === -1) {
          ids.push(campaignId);
          this.campaignService.getCampaignWithId(campaignId).subscribe(
            campaign => this.campaigns.update(c => ([...c, campaign]))
          );
        }
      }
    });
  };

  ngAfterViewInit (): void {
    this.selectFirstOpenAccordion();
  }

  toggleAction (accordion: CdkAccordionItem, index: number) {
    if (accordion != null) {
      accordion.toggle();
      if (accordion.expanded) {
        this.session.setSelectedCampaignIndex(index);
      }
      else {
        this.scrollIntoViewWithOffset(null, 0);
      }
    }
  };

  selectFirstOpenAccordion () {
    let selectedAccordionIndex: number = this.session.getSelectedCampaignIndex();
    if (selectedAccordionIndex > -1) {
      setTimeout(() => {
        let selectedAccordion = this.accordions.get(selectedAccordionIndex);
        if (selectedAccordion) {
          selectedAccordion.open();
        }
      }, 200);
    }
  };

  scrollIntoViewWithOffset (elementRef: ElementRef, offset: number = 0) {
    let position = 0;
    if (elementRef) {
      position = elementRef.nativeElement.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset;
    }
    window.scrollTo({
      behavior: 'smooth',
      top: position,
    });
  }

  opened (element: HTMLElement) {
    setTimeout(() => {
      let openedAccordionRef: ElementRef = new ElementRef(element);
      this.scrollIntoViewWithOffset(openedAccordionRef, 122); // TNK - Inital value: 120
    });
  }

  setCampaign (campaign: Campaign) {
    this.session.setCampaign(campaign);
  };

  gotoRace (campaign: Campaign, race: Race) {
    this.session.setCampaignAndRace(campaign, race);
    return this.router.navigate(['/race', race._id, 'starSystemDisplay']);
  };

  // Tim just to get by: AreYouSure2Component
  deleteCampaign (evt: Event, campaign: Campaign): void {
    evt.stopPropagation();
    const dialogRef = this.dialog.open<string>(AreYouSure2Component, {
      width: '65%',
      height: '75%',
      panelClass: 'edit-dialog',
      data: {
        // title: "Warning",
        // message: "This cannot be undone!",
        documentName: "Campaign",
        document: campaign
      },
    });

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.session.iAmBusy();
        let campaignId = result['document']['_id'];
        this.campaignService.deleteCampaign(campaignId).pipe(
          tap(() => {
            this.racesForCampaignId.delete(campaignId);
            this.campaigns.update(campaigns => campaigns.filter(c => (c._id !== campaignId)));
          })
        ).subscribe(() => {
          this.session.iAmNotBusy();
        });
      }
    });
  }
}
