export enum TaskState {
  begin = 'begin',
  inProcess = 'inProcess',
  stopped = 'stopped',
  completed = 'completed'
};

export interface Task {
  _id?: string,
  campaignId?: string,
  turn: number,
  hours: number,
  fleetCount: number,
  fleetCounter?: number,
  state: TaskState;
}
