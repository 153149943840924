import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { AuthService } from './auth/auth.service';
import { SessionService } from './services/session.service';
import { UtilityService } from './services/utility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public authService = inject(AuthService);
  public session = inject(SessionService);
  private utilityService = inject(UtilityService);

  title = 'iSkyMarshal Campaign Mgr 17';

  constructor () {
    this.session.menuLeftVisible = !this.session.isMobileDevice;

    this.session.backendVersion = toSignal(this.utilityService.getBackendVersion());
    this.session.frontendVersion = this.utilityService.getFrontendVersion();
  };

  ngOnInit () { };
}
