import { Component, inject } from '@angular/core';

import { UserService } from '../../services/user.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'user-register',
  templateUrl: './user-register.component.html',
  host: {
    'class': 'flex flex-grow'
  }
})
export class UserRegisterComponent {
  authService = inject(AuthService);
  userService = inject(UserService);
  public first: string = '';
  public last: string = '';
  public email: string = '';
  public password: string = '';
  public role: string = '';
  passwordRepeat: string = '';

  onSubmit () {
    this.userService.createUser(this.first, this.last, this.email, this.password).subscribe(
      (user) => {
        if (user) {
          this.authService.login(this.email, this.password, true);
        }
      }
    );
  };
};
