<div class="m-0 p-2 md:p-0 font-bold border-0 border-white flex flex-col top-16 ism-white text-1.5 scrollbar-none overflow-x-clip">
  <div>Race Encounters</div>

  <!-- -->
  <cdk-accordion class="my-2 ism-accordion scrollbar-none">
    @for (encounter of encounters(); track encounter) {
      <cdk-accordion-item #accordionItem="cdkAccordionItem" class="ism-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + $index" [attr.aria-controls]="'accordion-body-' + $index" [attr.aria-expanded]="accordionItem.expanded" #thisAccordionItem (opened)="opened(thisAccordionItem)">
        <!-- Encounter Summary -->
        <div class="flex flex-row justify-start" (click)="accordionItem.toggle()">
          <!-- <div class="w-full flex items-center justify-between iBTTiny:flex-col lanPixel2xl:flex-row"> -->
          <!-- <div class="flex flex-row flex-grow"> -->
          <a type="button" class="btn" href="/ibm.html?reid={{ raceEncountersByEncounterId[encounter._id]._id }}" target="_blank">iBM</a>
          <div class="m-1">
            {{ encounter.name }}
            @if (encounter.parentEncounterId) {
              (Child)
            }
          </div>
          <div class="m-1">Star System: {{ hashStarSystems[encounter.starSystemId]?.number }}</div>
          <div class="m-1">{{ encounter.scale }}</div>
          <div class="m-1">{{ encounter.status }}</div>
          <!-- </div>
        </div> -->
        </div>

        <!-- Encounter Details -->
        <div role="region" [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + $index" [attr.aria-labelledby]="'accordion-header-' + $index">
          <div class="flex flex-row flex-grow">
            @if (encounter.scale === "Interception" || encounter.scale === "Tactical") {
              <div class="m-1">System Hex: {{ encounter.systemHex }}</div>
              @if (encounter.scale === "Tactical") {
                <div class="m-1">Interception Hex: {{ encounter.interceptionHex }}</div>
              }
            }
            @if (encounter.encounterTurn > 0) {
              <div class="m-1">Turns completed: {{ encounter.encounterTurn }}</div>
            }
          </div>
          <div class="w-full flex">
            <table>
              <tr>
                <th></th>
                <th>Turn</th>
                <th>Pulse</th>
              </tr>
              <tr>
                <td>Strategic</td>
                <td class="text-right">{{ encounter.turnStrategic }}</td>
                <td class="text-right">{{ encounter.pulseStrategic }}</td>
              </tr>
              <tr>
                <td>System</td>
                <td class="text-right">{{ encounter.turnSystem }}</td>
                <td class="text-right">{{ encounter.pulseSystem }}</td>
              </tr>
              @if (encounter.scale === "Interception" || encounter.scale === "Tactical") {
                <tr>
                  <td>Interception</td>
                  <td class="text-right">{{ encounter.turnInterception }}</td>
                  <td class="text-right">{{ encounter.pulseInterception }}</td>
                </tr>
                @if (encounter.scale === "Tactical") {
                  <tr>
                    <td>Tactical</td>
                    <td class="text-right">{{ encounter.turnTactical }}</td>
                    <td class="text-right">{{ encounter.miniPulse }}</td>
                  </tr>
                }
              }
            </table>
            <div>
              <table>
                <tr>
                  <th>Status</th>
                  <th>Turn</th>
                  <th class="tooltip-tr" data-text="Fleet/Counter Counts">F/C</th>
                </tr>
                <tr>
                  <td>{{ raceEncountersByEncounterId[encounter._id].status }}</td>
                  <td class="text-center">{{ raceEncountersByEncounterId[encounter._id].encounterTurn }}</td>
                  <td class="tooltip-tr" data-text="Fleet/Counter Counts">{{ raceEncountersByEncounterId[encounter._id].fleetIds?.length || 0 }}/{{ raceEncountersByEncounterId[encounter._id].counters?.length || 0 }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </cdk-accordion-item>
      <br />
    }
    <!-- -->
    @empty {
      <div class="">No Encounters</div>
    }
  </cdk-accordion>
</div>
