import { Component } from '@angular/core';

import { User } from "../../interfaces/user";

import { SessionService } from "../../services/session.service";
@Component({
  selector: 'user-reset-password',
  templateUrl: './user-reset-password.component.html',
  host: {
    'class': 'flex flex-grow'
  }
})
export class UserResetPasswordComponent {
  myPageName: string = 'user-reset-password';
  public first: string = '';
  public last: string = '';
  public email: string = '';
  public password: string = '';
  public role: string = '';
  public showPassword: boolean = false;

}
