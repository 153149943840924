import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { FormsModule } from "@angular/forms";

import { CdkAccordionModule } from '@angular/cdk/accordion';

import { SharedModule } from "../shared/shared.module";

import { UserAccountEditComponent } from "./user-account-edit/user-account-edit.component";
import { UserAssignmentsComponent } from "./user-assignments/user-assignments.component";
import { UserForgotPasswordComponent } from "./user-forgot-password/user-forgot-password.component";
import { UserListComponent } from "./user-list/user-list.component";
import { UserLoginComponent } from "./user-login/user-login.component";
import { UserRegisterComponent } from "./user-register/user-register.component";
import { UserResetPasswordComponent } from "./user-reset-password/user-reset-password.component";
import { UserRoutingModule } from './user-routing.module';

@NgModule({
  imports: [
    CdkAccordionModule,
    CommonModule,
    DialogModule,
    FormsModule,
    SharedModule,
    UserRoutingModule,
  ],
  declarations: [
    UserAccountEditComponent,
    UserAssignmentsComponent,
    UserForgotPasswordComponent,
    UserLoginComponent,
    UserListComponent,
    UserRegisterComponent,
    UserResetPasswordComponent,
  ],
  exports: [
    SharedModule,
    UserAccountEditComponent,
    UserAssignmentsComponent,
    UserForgotPasswordComponent,
    UserListComponent,
    UserLoginComponent,
    UserRegisterComponent,
    UserResetPasswordComponent,
  ],
})
export class UserModule { }
