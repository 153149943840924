import { Component, Injector, Signal, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { ColDef } from 'ag-grid-community'; // Column Definition Type Interface

import { CampaignService } from '../../services/campaign.service';
import { SessionService } from '../../services/session.service';
import { TableService } from '../../services/table.service';

import { authorizedMarshal } from '../../auth/auth.guard';

import { Campaign } from '../../interfaces/campaign';

@Component({
  selector: 'campaign-tables',
  templateUrl: './campaign-tables.component.html'
})
export class CampaignTablesComponent {
  private injector = inject(Injector);
  authorizedMarshal: boolean;
  // campaignId: string = "";
  pageName: string = "campaign-tables";
  campaign: Campaign;
  // activeTable: number = 1;
  tables = [
    {
      title: "System Types",
      // gridOptions: localData.systemTypes// gridOptions,
      colDefs: [
        { field: 'low', width: 80 },
        { field: 'high', width: 80 },
        { field: 'type', flex: 2 },
        { field: 'wpModifier', flex: 1, headerName: 'WP Modifier', headerTooltip: 'Warp Point Modifier' },
      ],
      tableType: 'systemTypes'
    },
    {
      title: "Star Types",
      // gridOptions: localData.starTypes// gridOptions,
      colDefs: [
        { field: 'low', headerName: 'Low', width: 80 },
        { field: 'high', headerName: 'High', width: 80 },
        { field: 'type', headerName: 'Type', width: 80 },
        { field: 'diameter', headerName: 'Dia.', headerTooltip: 'Diameter', width: 80 },
        { field: 'wpModifier', headerName: 'WPMod', headerTooltip: 'Warp Point Modifier', width: 80 },
        { field: 'rockyMax', headerName: 'Rocky Max', width: 80 },
        { field: 'gasMin', headerName: 'GMin', headerTooltip: 'Gas Minimum', width: 80 },
        { field: 'gasMax', headerName: 'GMax', headerTooltip: 'Gas Maximum', width: 80 },
        { field: 'iceMin', headerName: 'IMin', headerTooltip: 'Ice Minimum', width: 80 },
        { field: 'biosphereMin', headerName: 'BioMin', headerTooltip: 'Biosphere Minimum', width: 80 },
        { field: 'biosphereMax', headerName: 'BioMax', headerTooltip: 'Biosphere Maximum', width: 80 },
        { field: 'tideLockMax', headerName: 'TMax', headerTooltip: 'Tidelock Maximum', width: 80 },
        { field: 'maxOrbit', headerName: 'Max Orbit', width: 80 },
        { field: 'info', headerName: 'Info', width: 80 },
      ],
      tableType: 'starTypes'
    },
    {
      title: "Binary Distances",
      // gridOptions: localData.binaryDistances// gridOptions,
      colDefs: [
        { field: 'low', flex: 1 },
        { field: 'high', flex: 1 },
        { field: 'distance', flex: 1 },
        { field: 'info', flex: 1 },
      ],
      tableType: 'binaryDistances'
    },
    {
      title: "Rocky Planet Types",
      // gridOptions: localData.rockyPlanetTypes// gridOptions,
      colDefs: [
        { field: 'low', width: 80 },
        { field: 'high', width: 80 },
        { field: 'innerType', flex: 1 },
        { field: 'biosphereType', flex: 1 },
        { field: 'outerType', flex: 1 },
        { field: 'info', flex: 1 },
      ],
      tableType: 'rockyPlanetTypes'
    },
    {
      title: "Planet Moon Count Modifiers",
      // gridOptions: localData.planetMoonCountModifiers// gridOptions,
      colDefs: [
        { field: 'type', width: 80 },
        { field: 'modifier', width: 80 },
      ],
      tableType: 'planetMoonCountModifiers'
    },
    {
      title: "Moon Counts",
      // gridOptions: localData.moonCounts// gridOptions,
      colDefs: [
        { field: 'low', width: 80 },
        { field: 'high', width: 80 },
        { field: 'count', width: 80 },
        { field: 'info', flex: 1 },
      ],
      tableType: 'moonCounts'
    },
    {
      title: "Warp Point Numbers",
      // gridOptions: localData.warpPointNumbers// gridOptions,
      colDefs: [
        { field: 'low', width: 80 },
        { field: 'high', width: 80 },
        { field: 'count', width: 80 },
        { field: 'dieRoll', width: 80 },
        { field: 'info', flex: 1 },
      ],
      tableType: 'warpPointNumbers'
    },
    {
      title: "Warp Point Types",
      // gridOptions: localData.warpPointTypes// gridOptions,
      colDefs: [
        { field: 'low', width: 80 },
        { field: 'high', width: 80 },
        { field: 'type', width: 80 },
        { field: 'open', width: 80 },
        { field: 'capacity', width: 80 },
        { field: 'info', flex: 1 },
      ],
      tableType: 'warpPointTypes'
    },
    {
      title: "Warp Point Distances",
      // gridOptions: localData.warpPointDistances// gridOptions,
      colDefs: [
        { field: 'low', headerName: 'Low', width: 80 },
        { field: 'high', headerName: 'High', width: 80 },
        { field: 'hexDistance', headerName: 'Hex Distances []', type: 'string' }
      ],
      tableType: 'warpPointDistances'
    },
    {
      title: "Civilization Probabilities",
      // gridOptions: localData.civilizationProbabilities// gridOptions,
      colDefs: [
        { field: 'low', width: 80 },
        { field: 'high', width: 80 },
        { field: 'dieModifier', width: 80 },
        { field: 'type', width: 80 },
        { field: 'typeName', flex: 1 },
        { field: 'info', flex: 1 },
      ],
      tableType: 'civilizationProbabilities'
    },
    {
      title: "Population Sizes",
      // gridOptions: localData.populationSizes// gridOptions,
      colDefs: [
        { field: 'low', width: 80 },
        { field: 'high', width: 80 },
        { field: 'multiplier', width: 80 },
        { field: 'addition', width: 80 },
        { field: 'info', flex: 1 },
      ],
      tableType: 'populationSizes'
    },
    {
      title: "Governments",
      // gridOptions: localData.governments// gridOptions,
      colDefs: [
        { field: 'governmentId', headerName: 'ID', enableCellEdit: false, width: 80 },
        { field: 'statLookup', headerName: 'STAT', enableCellEdit: false, width: 80 },
        { field: 'low', headerName: 'Low', width: 80 },
        { field: 'high', headerName: 'High', width: 80 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'contact', headerName: 'Contact', flex: 1 },
        { field: 'trade', headerName: 'Trade', flex: 1 },
        { field: 'military', headerName: 'Mil', flex: 1 },
        { field: 'partnership', headerName: 'Part', flex: 1 },
        { field: 'amalgation', headerName: 'Amal', flex: 1 },
        { field: 'warships', headerName: 'Warships', flex: 1 },
        { field: 'defense', headerName: 'Defense', flex: 1 },
        { field: 'industrial', headerName: 'IU', flex: 1 },
        { field: 'freighters', headerName: 'Freighters', flex: 1 },
        { field: 'pcf', headerName: 'PCF', flex: 1 },
        { field: 'grade', headerName: 'Grade', flex: 1 },
        { field: 'shipyard', headerName: 'SY', flex: 1 }
      ],
      tableType: 'governments'
    },
    {
      title: "System Exploitations",
      // gridOptions: localData.systemExploitations// gridOptions,
      colDefs: [
        { field: 'low', width: 80 },
        { field: 'high', width: 80 },
        { field: 'ptu', width: 80 },
        { field: 'info', flex: 1 },
      ],
      tableType: 'systemExploitations'
    },
    {
      title: "Economic Level Research",
      // gridOptions: localData.economicLevelResearch// gridOptions,
      // startUpFee":2000,"monthlyFee":250,"requiredPoints":220,"description
      colDefs: [
        { field: 'code', headerName: 'Code', enableCellEdit: false, width: 80 },
        { field: 'startUpFee', width: 80 },
        { field: 'monthlyFee', width: 80 },
        { field: 'requiredPoints', width: 80 },
        { field: 'description', flex: 1 },
      ],
      tableType: 'economicItems'
    },
    {
      title: "To Hit",
      // gridOptions: localData.toHit// gridOptions,
      colDefs: [
        { field: 'name', headerName: 'Name', editable: false, width: 80 },
        { field: 'toHit', headerName: 'To Hit Array', editable: true, flex: 1 },
      ],
      tableType: 'toHit'
    },
    {
      title: "Damage",
      // gridOptions: localData.damage// gridOptions,
      colDefs: [
        { field: 'name', headerName: 'Name', editable: false, width: 80 },
        { field: 'damage', headerName: 'Damage Array', editable: true, flex: 1 },
      ],
      tableType: 'damage'
    },
    {
      title: "Project Items",
      // gridOptions: localData.projectItems// gridOptions,
      colDefs: [
        { field: 'techLevel', headerName: 'Tech Level', width: 80 },
        { field: 'code', headerName: 'Code', enableCellEdit: false, width: 80 },
        { field: 'cost', headerName: 'Cost', width: 80 },
        { field: 'developmentCost', headerName: 'Dev Cost', flex: 1 },
        { field: 'developmentPoints', headerName: 'Dev Points', flex: 1 },
        { field: 'hullSpace', headerName: 'Hull Space', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 2 },
        { field: 'symbol', headerName: 'Symbol', width: 80 },
        { field: 'notations', headerName: 'Notations', flex: 1 }
      ],
      tableType: 'projectItems'
    }
  ];
  selectedTable: any;
  data: Signal<any[]>;
  columnDefs: ColDef[] = [];

  constructor (
    private campaignService: CampaignService,
    public session: SessionService,
    private tableService: TableService,
  ) {
    this.authorizedMarshal = authorizedMarshal();
  };

  ngOnInit (): void {
    this.campaign = this.session.getCampaign();
    this.loadAllTableData();
    this.selectedTable = this.tables[0];
    this.data = this.selectedTable['data'];
    this.columnDefs = this.selectedTable['colDefs'];
  };

  loadTableData (table: any) {
    console.log('loadTableData', table);
    table['data'] = toSignal(this.tableService.getTableDataForType(table.tableType, this.campaign._id), { injector: this.injector });
  };

  loadAllTableData () {
    for (let table of this.tables) {
      this.loadTableData(table);
    }
  };

  displayTable (table: { [key: string]: any; }) {
    this.selectedTable = table;
    this.data = table['data'];
    this.columnDefs = table['colDefs'];
  };

  saveTableData (table: any) {
    throw new Error('Method not implemented.');
  };

  deleteTableData (table: any) {
    throw new Error('Method not implemented.');
  };

  revertChanges (table: any) {
    throw new Error('Method not implemented.');
  };
}
