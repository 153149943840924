import { Component, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { CampaignWithRace } from '../../base/campaign-race';

import { AuthService } from '../../auth/auth.service';
import { StarSystemService } from '../../services/star-system.service';
import { TableService } from '../../services/table.service';
import { UserService } from '../../services/user.service';

import { Government } from '../../interfaces/government';
import { StarSystem, Planet } from '../../interfaces/star-system';
import { User } from '../../interfaces/user';

@Component({
  selector: 'race-display',
  templateUrl: './race-properties.component.html',
})
export class RacePropertiesComponent extends CampaignWithRace {
  private authService = inject(AuthService);
  private starSystemService = inject(StarSystemService);
  private tableService = inject(TableService);
  private userService = inject(UserService);

  labelsReadOnly: string = "bg-gray-400 bg-opacity-40 text-black";
  valuesReadOnly: string = "px-2 w-full bg-gray-400 bg-opacity-40 flex items-center rounded-r-md";

  isMarshal: boolean = this.authService.isMarshal();
  isMaster: boolean = this.authService.isMaster();

  ready = signal<boolean>(true);
  government = signal<Government>(undefined);
  govStats: string = undefined;
  starSystem = signal<StarSystem>(undefined);

  isFirstTurn: boolean = false;
  isFirstTurnDone: boolean = true;
  homePlanet = signal<Planet>(undefined);

  users = toSignal<User[]>(this.userService.getAllUsers());

  limitedGovernments = signal(undefined);
  newGovernment: number = undefined;

  setLimitedGovernments (statLookup: string) {
    let governments: Government[] = this.tableService.getGovernments();
    this.newGovernment = undefined;
    this.limitedGovernments.set(
      governments.filter(
        (gov) => gov.statLookup === statLookup
      ).sort(
        (a, b) => a.low - b.low
      )
    );
  };

  constructor () {
    super();

    effect(() => {
      if (!this.race() || !this.campaign()) {
        return;
      }
      this.isFirstTurn = (this.race().turnActivated === this.campaign().turn);
      this.isFirstTurnDone = (this.race().economicsCompleteTurn >= this.race().turnActivated);
      this.tableService.getGovernmentById(this.race().governmentId, this.campaign()._id).subscribe(gov => {
        this.government.set(gov);
        this.govStats = this.government().statLookup;
        setTimeout(() => {
          this.setLimitedGovernments(this.government().statLookup);
        });
      });
      this.starSystemService.getStarSystem(this.race().starSystemId).subscribe(starSystem => {
        this.starSystem.set(starSystem);
        for (const star of starSystem.stars) {
          for (const planet of star.planets) {
            if (planet._id === this.race().homePlanetId) {
              this.homePlanet.set(planet);
            }
          }
        }
      });
    }, { allowSignalWrites: true });
  };

  getNewGovStats () {
    let activations = [
      { statType: 'M', value: this.race()?.racialMilitarism - this.race()?.successMilitarism },
      { statType: 'D', value: this.race()?.racialDetermination - this.race()?.successDetermination },
      { statType: 'C', value: this.race()?.racialChauvinism - this.race()?.successChauvinism }
    ];

    activations = activations.filter(
      active => active.value >= 0
    ).sort(
      (a, b) => (a.value - b.value)
    );
    let baseStat = activations.reduce((stat, element) => (stat + element.statType), '') + '___';
    return baseStat.substring(0, 3);
  };

  resetGovernmentList () {
    this.govStats = this.getNewGovStats();
    this.setLimitedGovernments(this.govStats);
  };

  setGovernment () {
    this.race().governmentId = this.newGovernment[0];
  }

  saveRace (): void {
    this.session.iAmBusy();
    if (this.isMarshal && !this.isFirstTurnDone) {
      console.log('First turn not done.  Check for what needs changing!');
    }
    this.raceService.saveRace(this.race()).subscribe(
      race => {
        this.race.set(race);
        this.session.setRace(race);
        this.session.setNotifyMessage('Race saved');
        this.session.iAmNotBusy();
      }
    );
  };
};
