<div id="_left-race-menu_" class="w-full scrollbar-none" #elementTop>
  <cdk-accordion class="w-full relative border-none ism-accordion scrollbar-none" [openLink]="linkToFollow">
    <cdk-accordion-item id="_Race_Displays_" class="ism-accordion-item" indexId="0" #accordionItem0="cdkAccordionItem" role="button" tabindex="0" [attr.aria-controls]="'accordion-body-0'" [attr.aria-description]="'Multiple Race Data Display'" (opened)="opened(0)">
      <div id="_Race-Display-Header_" class="w-full flex items-center" (click)="accordionItem0.toggle()">
        <chevron-toggle [isOpen]="accordionItem0.expanded"></chevron-toggle>
        <div id="_Race-Displays_" class="menuLeftSection" routerLinkActive="ngActiveLinkGreen">Race Displays</div>
      </div>

      <div id="_BODY_Race-Display-Header_" class="ml-6 w-full flex flex-col" role="region" [style.display]="accordionItem0.expanded ? '' : 'none'" [attr.id]="'accordion-body-0'" [attr.aria-labelledby]="'accordion-header-0'">
        <!-- TNK (click)="scrollToTop()" -->
        <div id="_Star-Systems_" [routerLink]="['/race', race()._id, 'starSystemDisplay']" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Star Systems</div>
        <div id="_Race-Properties_" [routerLink]="['/race', race()._id, 'raceProperties']" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Race Properties</div>
        <div id="_Race-Encounters_" [routerLink]="['/race', race()._id, 'raceEncounters']" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Race Encounters</div>
      </div>
    </cdk-accordion-item>

    <cdk-accordion-item id="_Economics-Startup_" indexId="1" #accordionItem1="cdkAccordionItem" role="button" tabindex="0" [attr.id]="'accordion-header-1'" [attr.aria-controls]="'accordion-body-1'" [attr.aria-description]="'Startup Turn Economic Pages'" (opened)="opened(1)" [ngClass]="{ hidden: !session.isFirstTurn() }">
      <div class="flex items-center" (click)="accordionItem1.toggle()">
        <chevron-toggle [isOpen]="accordionItem1.expanded"></chevron-toggle>
        <div class="menuLeftSection" routerLinkActive="ngActiveLinkGreen">Economics - Startup</div>
      </div>
      <div class="ml-6 w-full flex flex-col" role="region" [style.display]="accordionItem1.expanded ? '' : 'none'" [attr.id]="'accordion-body-1'" [attr.aria-labelledby]="'accordion-header-1'">
        <div [routerLink]="['/race', race()._id, 'economics', 'startup', 'suSummary']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="menuLeftItem" (click)="session.closeMobileMenus()">Summary</div>
        @if (race() && race().techLevel > 0) {
          <div [routerLink]="['/race', race()._id, 'economics', 'startup', 'warships']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="menuLeftItem" (click)="session.closeMobileMenus()">Warships</div>
        }
        <div [routerLink]="['/race', race()._id, 'economics', 'startup', 'defenses']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="menuLeftItem" (click)="session.closeMobileMenus()">Defenses</div>
        <div [routerLink]="['/race', race()._id, 'economics', 'startup', 'freighters']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="menuLeftItem" (click)="session.closeMobileMenus()">Freighters</div>
        <div [routerLink]="['/race', race()._id, 'economics', 'startup', 'population']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="menuLeftItem" (click)="session.closeMobileMenus()">Population</div>
        <div [routerLink]="['/race', race()._id, 'economics', 'startup', 'troops']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="menuLeftItem" (click)="session.closeMobileMenus()">Planetary Ctrl Forces</div>
      </div>
    </cdk-accordion-item>

    <cdk-accordion-item id="_Economics-Turn_" indexId="2" #accordionItem2="cdkAccordionItem" role="button" tabindex="0" [attr.id]="'accordion-header-2'" [attr.aria-controls]="'accordion-body-2'" [attr.aria-description]="'Regular Turn Income & Expense Pages'" (opened)="opened(2)" [ngClass]="{ hidden: session.isFirstTurn() }">
      <div class="w-fit flex items-center" [scrollToTop]="elementTop" (click)="accordionItem2.toggle()">
        <chevron-toggle [isOpen]="accordionItem2.expanded"></chevron-toggle>
        <div class="menuLeftSection" routerLinkActive="ngActiveLinkGreen">Economics - Turn</div>
      </div>
      <div class="ml-6 w-full flex flex-col" role="region" [style.display]="accordionItem2.expanded ? '' : 'none'" [attr.id]="'accordion-body-2'" [attr.aria-labelledby]="'accordion-header-2'">
        <div [routerLink]="['/race', race()._id, 'economics', 'summary']" ariaCurrentWhenActive="page" class="menuLeftItem" (click)="session.closeMobileMenus()">Budget Summary</div>
        <div class="pl-8 text-yellow-300 text-1.125">
          Income
          <div class="flex flex-col">
            <div [routerLink]="['/race', race()._id, 'economics', 'income', 'population']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" (click)="session.closeMobileMenus()">Population Income</div>
            <div [routerLink]="['/race', race()._id, 'economics', 'income', 'trade']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" (click)="session.closeMobileMenus()">Trade Income</div>
            <div [routerLink]="['/race', race()._id, 'economics', 'income', 'ship-lease']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" (click)="session.closeMobileMenus()">Ship Leases</div>
            <div [routerLink]="['/race', race()._id, 'economics', 'income', 'ship-sales']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" (click)="session.closeMobileMenus()">Ship Sales</div>
            <div [routerLink]="['/race', race()._id, 'economics', 'income', 'other-income']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" (click)="session.closeMobileMenus()">Other Income</div>
          </div>
        </div>
        <div class="pl-8 text-yellow-300 text-1.125">
          Expenses
          <div class="flex flex-col">
            <div [routerLink]="['/race', race()._id, 'economics', 'expenses', 'maintenance']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" (click)="session.closeMobileMenus()">Maintenance</div>
            <div [routerLink]="['/race', race()._id, 'economics', 'expenses', 'construction']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" (click)="session.closeMobileMenus()">Construction</div>
            <div [routerLink]="['/race', race()._id, 'economics', 'expenses', 'industrial-expansion']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" (click)="session.closeMobileMenus()">IU Expansion</div>
            <div [routerLink]="['/race', race()._id, 'economics', 'expenses', 'colonization']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" (click)="session.closeMobileMenus()">Colonization</div>
            <div [routerLink]="['/race', race()._id, 'economics', 'expenses', 'troops']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" (click)="session.closeMobileMenus()">Troops</div>
            <div [routerLink]="['/race', race()._id, 'economics', 'expenses', 'economic-research']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" (click)="session.closeMobileMenus()">Level Research</div>
            <div [routerLink]="['/race', race()._id, 'economics', 'expenses', 'project-research']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" (click)="session.closeMobileMenus()">Tech Projects</div>
            <div [routerLink]="['/race', race()._id, 'economics', 'expenses', 'other-expenses']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" (click)="session.closeMobileMenus()">Other Misc</div>
          </div>
        </div>
      </div>
    </cdk-accordion-item>

    <cdk-accordion-item id="_Messages_" indexId="3" #accordionItem3="cdkAccordionItem" role="button" tabindex="0" [attr.id]="'accordion-header-3'" [attr.aria-controls]="'accordion-body-3'" [attr.aria-description]="'All Messaging Pages'" (opened)="opened(3)">
      <div class="flex items-center" (click)="accordionItem3.toggle()">
        <chevron-toggle [isOpen]="accordionItem3.expanded"></chevron-toggle>
        <div class="menuLeftSection" routerLinkActive="ngActiveLinkGreen">Messages</div>
      </div>
      <div class="ml-6 w-full flex flex-col" role="region" [style.display]="accordionItem3.expanded ? '' : 'none'" [attr.id]="'accordion-body-3'" [attr.aria-labelledby]="'accordion-header-3'">
        <div [routerLink]="['/race', race()._id, 'messages', 'notifications']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="menuLeftItem" (click)="session.closeMobileMenus()">Notifications</div>
        <div [routerLink]="['/race', race()._id, 'messages', 'chatroom']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="menuLeftItem" (click)="session.closeMobileMenus()">Chatroom</div>
        <div [routerLink]="['/race', race()._id, 'messages', 'player-to-player']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="menuLeftItem" (click)="session.closeMobileMenus()">Player to Player</div>
        <div [routerLink]="['/race', race()._id, 'messages', 'race-to-race']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="menuLeftItem" (click)="session.closeMobileMenus()">Race to Race</div>
        <div [routerLink]="['/race', race()._id, 'messages', 'negotiations']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="menuLeftItem" (click)="session.closeMobileMenus()">Negotiations</div>
        <div [routerLink]="['/race', race()._id, 'messages', 'encounters']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="menuLeftItem" (click)="session.closeMobileMenus()">Encounters</div>
      </div>
    </cdk-accordion-item>

    <cdk-accordion-item id="_Admiralty_" indexId="4" #accordionItem4="cdkAccordionItem" role="button" tabindex="0" [attr.id]="'accordion-header-4'" [attr.aria-controls]="'accordion-body-4'" [attr.aria-description]="'Fleet Orders, Fleet, Ship, and Ship Class Design'" (opened)="opened(4)">
      <div class="flex items-center" (click)="accordionItem4.toggle()">
        <chevron-toggle [isOpen]="accordionItem4.expanded"></chevron-toggle>
        <div class="menuLeftSection" routerLinkActive="ngActiveLinkGreen">Admiralty</div>
      </div>
      <div class="ml-6 w-full flex flex-col" role="region" [style.display]="accordionItem4.expanded ? '' : 'none'" [attr.id]="'accordion-body-4'" [attr.aria-labelledby]="'accordion-header-4'">
        <div [routerLink]="['/race', race()._id, 'admiralty', 'fleet-orders']" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Fleet Orders</div>
        <div [routerLink]="['/race', race()._id, 'admiralty', 'fleet-administration']" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Fleet Administration</div>
        <div [routerLink]="['/race', race()._id, 'admiralty', 'ship-administration']" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Ship Administration</div>
        <div [routerLink]="['/race', race()._id, 'admiralty', 'class-designs']" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Class Designs</div>
        <div [routerLink]="['/race', race()._id, 'admiralty', 'shared-designs']" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Shared Designs</div>

        <a href="/isy.html?rid={{ race()._id }}" class="menuLeftItem" target="_blank">iShipYard</a>
      </div>
    </cdk-accordion-item>

    <cdk-accordion-item id="_Race_Relations_" indexId="5" #accordionItem5="cdkAccordionItem" role="button" tabindex="0" [attr.id]="'accordion-header-5'" [attr.aria-controls]="'accordion-body-5'" [attr.aria-description]="'Communication and Diplomacy Pages'" (opened)="opened(5)">
      <div class="flex items-center" (click)="accordionItem5.toggle()">
        <chevron-toggle [isOpen]="accordionItem5.expanded"></chevron-toggle>
        <div class="menuLeftSection" routerLinkActive="ngActiveLinkGreen">Race Relations</div>
      </div>
      <div class="ml-6 w-full flex flex-col" role="region" [style.display]="accordionItem5.expanded ? '' : 'none'" [attr.id]="'accordion-body-5'" [attr.aria-labelledby]="'accordion-header-5'">
        <!-- <div routerLink="/race/stateCommunication" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Communications</div>
        <div routerLink="/race/stateNegotiation" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Negotiations</div> -->

        <div [routerLink]="['/race', race()._id, 'state', 'firstContact']" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" [scrollToTop]="elementTop" (click)="session.closeMobileMenus()">First Contact Policy</div>
        <!-- -->
        <div class="pl-8 text-yellow-300 text-1.125">
          Communication
          <div class="flex flex-col border border-x-0">
            <div [routerLink]="['/race', race()._id, 'state', 'communication']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" [scrollToTop]="elementTop" (click)="session.closeMobileMenus()">In Process</div>
            <!-- -->
            <div [routerLink]="['/race', race()._id, 'state', 'communicationEstablished']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" [scrollToTop]="elementTop" (click)="session.closeMobileMenus()">Established</div>
            <!-- -->
            <div [routerLink]="['/race', race()._id, 'state', 'communicationEstablishedFailed']" routerLinkActive="ngActiveLinkGreen" ariaCurrentWhenActive="page" class="sub-menuLeftItem" [scrollToTop]="elementTop" (click)="session.closeMobileMenus()">Failed</div>
            <!-- -->
          </div>
        </div>
        <!-- -->
        <div [routerLink]="['/race', race()._id, 'state', 'negotiation']" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" [scrollToTop]="elementTop" (click)="session.closeMobileMenus()">Negotiations</div>
        <!-- <a id="_State-Negotiation" href="/stateNegotiation" class="menuLeftItem"
                ng-click="ctrl.scrollToTop();ac.session.menuMobileLeftVisble=false">Negotiations</a> -->
      </div>
    </cdk-accordion-item>

    <cdk-accordion-item id="_Tutorials_" indexId="6" #accordionItem6="cdkAccordionItem" role="button" tabindex="0" [attr.id]="'accordion-header-6'" [attr.aria-controls]="'accordion-body-6'" [attr.aria-description]="'How Do I... Pages'" (opened)="opened(6)">
      <div class="flex items-center" (click)="accordionItem6.toggle()">
        <chevron-toggle [isOpen]="accordionItem6.expanded"></chevron-toggle>
        <div class="menuLeftSection" routerLinkActive="ngActiveLinkGreen">Tutorial</div>
      </div>
      <div class="ml-6 w-full flex flex-col" role="region" [style.display]="accordionItem6.expanded ? '' : 'none'" [attr.id]="'accordion-body-6'" [attr.aria-labelledby]="'accordion-header-6'">
        <div routerLink="/tutorGeneral" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Basics</div>
        <div routerLink="/tutorStartup" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Startup</div>
        <div routerLink="/tutorTurnEconomics" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Turn Economics</div>
        <div routerLink="/tutorTurnOrders" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">Turn Orders</div>
        <div routerLink="/tutorBattleManager" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">iBattleManager</div>
        <div routerLink="/tutorWarpMap" routerLinkActive="ngActiveLinkGreen" class="menuLeftItem" (click)="session.closeMobileMenus()">iWarpMap</div>
      </div>
    </cdk-accordion-item>
  </cdk-accordion>

  <div id="_Credits_" routerLink="/credits" routerLinkActive="ngActiveLinkGreen" class="menuLeftSection">Credits</div>
  <div class="h-6"></div>
</div>
