import { AfterViewInit, Component, ElementRef, inject, signal, viewChild } from '@angular/core';

import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

import { TableService } from '../../services/table.service';

interface Category {
  heading: string,
  table?: string,
  low: number,
  high: number;
};

@Component({
  selector: 'app-override-selection',
  templateUrl: './override-selection.component.html'
})
export class OverrideSelectionComponent extends DialogHandlerComponent implements AfterViewInit {
  tableService = inject(TableService);

  lowChoice = viewChild<ElementRef>('lowInput');
  highChoice = viewChild<ElementRef>('highInput');

  categories: { [key: string]: Category; } = {
    'systemTypes': {
      heading: 'System Types',
      table: 'systemTypes',
      low: 1,
      high: 100
    },
    'starTypes': {
      heading: 'Star Types',
      table: 'starTypes',
      low: 1,
      high: 100
    },
    'binaryDistances': {
      heading: 'Binary Distances',
      table: 'binaryDistances',
      low: 1,
      high: 100
    },
    'distance': {
      heading: 'Added Binary Distance',
      low: 1,
      high: 100
    },
    'orbitDeterminationLow': {
      heading: 'Orbit Determination - Low',
      low: 1,
      high: 8
    },
    'orbitDeterminationHigh': {
      heading: 'Orbit Determination - High',
      low: 3,
      high: 10
    },
    'rockyPlanetTypes': {
      heading: 'Rocky Planet Types',
      table: 'rockyPlanetTypes',
      low: 1,
      high: 100
    },
    'moonCounts': {
      heading: 'Moon Counts',
      table: 'moonCounts',
      low: 1,
      high: 100
    },
    'warpPointNumbers': {
      heading: 'Warp Point Numbers',
      table: 'warpPointNumbers',
      low: 1,
      high: 100
    },
    'warpPointDistances': {
      heading: 'Warp Point Distances',
      table: 'warpPointDistances',
      low: 1,
      high: 100
    },
    'civilizationProbabilities': {
      heading: 'Civilization Probabilities',
      table: 'civilizationProbabilities',
      low: 1,
      high: 100
    },
    'populationSizes': {
      heading: 'Population Sizes',
      table: 'populationSizes',
      low: 1,
      high: 100
    },
    'systemExploitations': {
      heading: 'System Exploitations',
      table: 'systemExploitations',
      low: 1,
      high: 100
    },
    'newSystemNumber': {
      heading: 'New System Number',
      low: 1,
      high: 100
    },
    'planetToAsteroid*': {
      heading: 'Planet To Asteroid',
      low: 1,
      high: 100
    },
    'moonTideLockCheck*': {
      heading: 'Moon Tide-Lock Check',
      low: 1,
      high: 100
    },
    'twinPlanetTypeDetermination*': {
      heading: 'Twin Planet Type Determination',
      low: 1,
      high: 100
    },
    'terranHabitabilityIndex*': {
      heading: 'Terran Habitability Index',
      low: 1,
      high: 10
    },
    'reiDetermination*': {
      heading: 'REI Determination',
      low: 1,
      high: 10
    },
    'warpPointDistanceOffset*': {
      heading: 'Warp Point Distance Offset',
      low: 1,
      high: 100
    },
    'techLevelDetermination*': {
      heading: 'Tech Level Determination',
      low: 1,
      high: 6
    },
    'techLevelDirect*': {
      heading: 'Tech Level Direct (Use same value!)',
      low: -2,
      high: 15
    }
  };
  keys = Object.keys(this.categories);
  selectedKey: string = undefined;

  selectedTable = signal<any[]>([]);
  low: number;
  high: number;
  overrides: { [key: string]: { low: number, high: number; }; } = {};
  overrideKeys = signal([]);

  constructor () {
    super();
    this.overrides = this.data['document'] as { [key: string]: { low: number, high: number; }; };
  };

  ngAfterViewInit () {
    this.selectedKey = this.keys[0];
    this.categoryChange(this.selectedKey);
  };

  categoryChange (key: string) {
    let category = this.categories[key];
    if (category.table) {
      this.tableService.getTableDataForType(category.table, this.session.campaign._id).subscribe((table) => {
        this.selectedTable.set(table);
      });
    }
    else {
      this.selectedTable.set(null);
    }
    this.low = category.low;
    this.high = category.high;

    this.lowChoice()?.nativeElement.setAttribute('min', this.low);
    this.highChoice()?.nativeElement.setAttribute('max', this.high);
  };

  add (key: string) {
    if (!this.low) {
      this.low = this.categories[key].low;
    }
    if (!this.high) {
      this.high = this.categories[key].high;
    }
    this.overrides[key] = {
      low: this.low,
      high: this.high
    };
    this.session['overrides'] = this.overrides;
    this.overrideKeys.set(Object.keys(this.overrides));
  };

  remove (key: string) {
    delete this.overrides[key];
    this.session['overrides'] = this.overrides;
    this.overrideKeys.set(Object.keys(this.overrides));
  };

  override save () {
    this.dialogRef.close({
      documentName: "Guidance",
      document: this.overrides,
    });
  };
};
