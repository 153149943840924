import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TutorialsRoutingModule } from './tutorials-routing.module';
import { BattleManagerComponent } from './battle-manager/battle-manager.component';
import { GeneralComponent } from './general/general.component';
import { StartUpEconomicsComponent } from './start-up-economics/start-up-economics.component';
import { TurnEconomicsComponent } from './turn-economics/turn-economics.component';
import { WarpMapComponent } from './warp-map/warp-map.component';
import { TurnFleetOrdersComponent } from './turn-fleet-orders/turn-fleet-orders.component';


@NgModule({
  declarations: [
    BattleManagerComponent,
    GeneralComponent,
    StartUpEconomicsComponent,
    TurnEconomicsComponent,
    WarpMapComponent,
    TurnFleetOrdersComponent
  ],
  imports: [
    CommonModule,
    TutorialsRoutingModule
  ]
})
export class TutorialsModule { }
