import { Component, Signal, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';

import { CampaignWithRace } from '../../base/campaign-race';

import { FleetService } from '../../services/fleet.service';
import { InformationStarSystemService } from '../../services/information-star-system.service';

import { Fleet } from '../../interfaces/fleet';
import { InformationStarSystem } from '../../interfaces/information-star-system';

@Component({
  selector: 'race-home',
  templateUrl: './race-home.component.html',
})
export class RaceHomeComponent extends CampaignWithRace {
  private informationStarSystemService = inject(InformationStarSystemService);
  private fleetService = inject(FleetService);

  starSystemId: string;
  showMoons: boolean = false;
  hideEmptyFleets: boolean = true;

  informationStarSystems: Signal<InformationStarSystem[] | undefined>;
  selectedIss$ = new Subject<InformationStarSystem>();
  fleets: Signal<Fleet[] | undefined>;

  constructor (
  ) {
    super();
    this.starSystemId = this.race().starSystemId as string;
    this.informationStarSystems = toSignal<InformationStarSystem[]>(
      this.informationStarSystemService.getInformationStarSystemForRaceId(this.race()._id)
    );

    effect(() => {
      let data = this.informationStarSystems();
      if (!data) {
        return;
      }
      else {
        let tempIss = data.find(
          iss => iss.starSystemId === this.starSystemId
        );
        if (tempIss) {
          this.selectedIss$.next(tempIss);
        }
      }
    });

    this.fleets = toSignal<Fleet[]>(
      this.fleetService.getFleetsForRaceId$(this.race()._id)
    );

    window.scrollTo(0, 0);
  };

  updateShowMoons () {
    this.showMoons = !this.showMoons;
  };

  updateShowEmptyFleets () {
    this.hideEmptyFleets = !this.hideEmptyFleets;
  }

  processSelectedStarSystemId (starSystemId: string) {
    this.starSystemId = starSystemId;
    let informationStarSystems = this.informationStarSystems();
    if (informationStarSystems) {
      let tempIss = informationStarSystems.find(iss => iss.starSystemId === starSystemId);
      if (tempIss) {
        this.selectedIss$.next(tempIss);
      }
    }
    window.scrollTo(0, 0);
  };
}
