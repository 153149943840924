import { Injectable, Signal, computed, inject, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, map, of, tap } from 'rxjs';
import { TaskState, Task } from '../interfaces/task';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  http = inject(HttpClient);

  constructor () { };

  experiment (campaignId: string): Observable<Task> {
    return this.http.put<Task>('/api/task/fleetOrders/' + campaignId, undefined);
  };

  experimentStatus (campaignId: string): Observable<Task> {
    return this.http.get<Task>('/api/task/fleetOrders/' + campaignId);
  };

  resetTaskById (taskId): Observable<Task> {
    return this.http.put<Task>('/api/task/reset/' + taskId, undefined);
  };
};
